import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import SwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import GDStatusBar from 'app/components/status/statusbar';
import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { GET_SETTING_CHATBOT, GET_SETTING_PORTAL_CHATBOT } from 'app/const/api/V2';
import { AVATAR_DEFAULT, COMMON } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { messageActionsAlert } from 'common/utils/StringUtils';
import { LIST_ACTION_MESSAGE } from '../../constants';
import FieldUploadAvatar from '../../multipleBranch/activePlan/FieldUploadAvatar';
import {
    ACTIVE_HOURS,
    BACKGROUND_VARIANT_OPTION,
    CHATBOT_SETTING_INFO,
    ICON_PORTAL_AI_SETTING,
    SETTING_PORTAL_AI,
    TAB_BUTTON_SHAPE
} from '../constants';
import { getEndpointAIAgent } from '../utils';
import ItemBackgroundVariant from './ItemBackgroundVariant';
import ItemButtonShape from './ItemButtonShape';
import ItemIcon from './ItemIcon';
import ItemPortalColor from './ItemPortalColor';
import LoadingSettings from './LoadingSettings';
import SettingReplyBehavior from './SettingReplyBehavior';
import TableSettingReply from './TableSettingReply';

const ChatbotSettings = () => {
    const { type: typeAgent } = useParams();
    const { t } = useTranslation(['addons', 'report']);
    const { NAME_AI, DEFAULT_ACTIVE_DAY } = CHATBOT_SETTING_INFO;
    const defaultColor = SETTING_PORTAL_AI.DEFAULT_COLOR;
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        all_time: 0,
        active_hours: 0,
        active_day: DEFAULT_ACTIVE_DAY,
        name: '',
        avatar: '',
        color: defaultColor,
        background_variant: '',
        button_shape: '',
        icon: ''
    });
    const {
        all_time: isAllTime,
        name: finalName,
        isLoading: finalIsLoading,
        avatar: finalAvt,
        color,
        icon,
        active_hours: activeReply,
        active_day,
        background_variant,
        button_shape = TAB_BUTTON_SHAPE[0].value
    } = state;
    const typeAgentSms = typeAgent === ADDONS_FOOTER_OPTIONS.CHATBOT_AI_SMS;
    const finalColor =
        SETTING_PORTAL_AI.LIST_COLOR_SETTING_AI_PORTAL.find(({ color: itemColor }) => itemColor === color) ||
        SETTING_PORTAL_AI.DEFAULT_COLOR;
    const finalBackgroundVariant =
        BACKGROUND_VARIANT_OPTION[background_variant?.toUpperCase()] || BACKGROUND_VARIANT_OPTION.SOLID_COLOR;
    const finalIcon =
        ICON_PORTAL_AI_SETTING.LIST_ICON.find(({ name }) => name === icon) || ICON_PORTAL_AI_SETTING.DEFAULT_ICON;
    const endpointAPI = getEndpointAIAgent({
        endPointAgentSms: GET_SETTING_CHATBOT,
        endPointAgentPortal: GET_SETTING_PORTAL_CHATBOT,
        typeAgent
    });

    const refAvatar = useRef(null);
    const refName = useRef(null);
    const refButtonSave = useRef(null);
    const refAlert = useRef(null);
    const refActiveHours = useRef(null);
    const refTableSettingReply = useRef(null);

    useEffect(() => {
        _getDetail();
    }, []);

    const _getDetail = () => {
        const _handleSuccess = ({ data = {} }) => {
            dispatchState((prev) => ({
                ...prev,
                ...(data || {}),
                isLoading: false,
                avatar: !!data?.avatar_id ? AVATAR_DEFAULT : data?.avatar
            }));
        };

        const _handleFail = (err) => {
            _showAlert(err);
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };

        clientQuery(endpointAPI, { method: 'GET', data: {} }, _handleSuccess, _handleFail);
    };

    const _getErrorMessage = ({ name = '' }) => {
        return t('customers:cannot_be_blank', { name: t(`addons:${name}`) });
    };

    const _handleSubmit = () => {
        const nameBot = refName.current.value.trim();
        const { avtObject, url } = refAvatar.current?.getValue() || {};

        const msgErrors = [];
        if (!nameBot) msgErrors.push(_getErrorMessage({ name: NAME_AI }));
        if (msgErrors.length) {
            _showAlert({ message: msgErrors });
            refButtonSave.current?.removeLoading();
            return;
        }

        let data = {
            avatar: avtObject || (url !== AVATAR_DEFAULT ? url : ''),
            name: nameBot
        };
        if (typeAgentSms) {
            data = {
                ...data,
                all_time: isAllTime,
                active_hours: refActiveHours.current.getValue()?.id || 0,
                active_day: refTableSettingReply.current ? refTableSettingReply.current.getValue() : DEFAULT_ACTIVE_DAY
            };
        } else {
            data = { ...data, color: finalColor.color, background_variant, button_shape, icon };
        }

        const _handleSuccess = ({ message }) => {
            _showAlert({
                message: messageActionsAlert({ message, type: t('settings'), action: LIST_ACTION_MESSAGE.UPDATED }),
                success: true
            });
        };

        const _handleFinally = () => {
            refButtonSave.current?.removeLoading();
        };

        clientQuery(
            endpointAPI,
            { method: 'PUT', data, toFormData: false },
            _handleSuccess,
            _showAlert,
            _handleFinally
        );
    };

    const _handleChangeToggle = (checked) => {
        dispatchState({ all_time: checked ? 1 : 0 });
    };

    const _showAlert = ({ message, success }) => {
        showStatusBar({ id: 'chatbot-setting', message, success, refAlert });
    };

    const _handleDisableSave = (value = false) => {
        refButtonSave.current?.setDisable(value);
    };

    const _handleChangeValue = ({ value = '', key = '' }) => {
        dispatchState((prev) => ({ ...prev, [key]: value }));
    };

    return (
        <div className="wrapper-chatbot --setting">
            <div className="flex-column gap-16">
                <h3 className="fs-18 fw-600 black">{t('addons:settings')}</h3>
                <GDStatusBar ref={refAlert} />
                {finalIsLoading ? (
                    <LoadingSettings isPortalAI={!typeAgentSms} />
                ) : (
                    <div className="box-setting">
                        <div className="flex-column gap-16">
                            <FieldUploadAvatar
                                ref={refAvatar}
                                isNotDivider
                                urlImage={finalAvt}
                                nameBranch={finalName}
                                titleButton={t('upload_new_picture')}
                                typePresign={COMMON.AVATAR}
                                avatarDefault={AVATAR_DEFAULT}
                                onUpFile={() => _handleDisableSave(true)}
                                onUpFileSuccess={() => _handleDisableSave(false)}
                            />
                            {typeAgentSms ? (
                                <div className="flex-column gap-2">
                                    <div className="fw-500 black-darker3">{t(`addons:${NAME_AI}`)}</div>
                                    <input
                                        ref={refName}
                                        className="field-input"
                                        defaultValue={finalName}
                                        placeholder={t('name')}
                                    />
                                </div>
                            ) : null}
                        </div>
                        {typeAgentSms ? (
                            <Fragment>
                                <div className="flex-column gap-10 box-setting__container">
                                    <div className="fw-700 black-darker3">{t('addons:hour_ai_operate')}</div>
                                    <div className="flex-betweenitems">
                                        <p className="fw-500 black-darker3">{t('addons:set_time_operate')}</p>
                                        <div className="switch large">
                                            <span className="switch__label">{t('report:all_time_date_picker')}</span>
                                            <SwitchCheckbox
                                                defaultChecked={isAllTime}
                                                id="toggle_setting_time"
                                                onChangeImmediately={_handleChangeToggle}
                                            />
                                        </div>
                                    </div>
                                    <SettingReplyBehavior
                                        ref={refActiveHours}
                                        behaviorHour={ACTIVE_HOURS}
                                        title={t('addons:active_hours')}
                                        defaultValue={activeReply}
                                        isHidden={!isAllTime}
                                    />
                                </div>
                                <TableSettingReply
                                    ref={refTableSettingReply}
                                    isHidden={isAllTime}
                                    activeDay={active_day}
                                />
                            </Fragment>
                        ) : (
                            <div className="setting-portal flex-column gap-16">
                                <div className="line">
                                    <span className="txt">{t(`addons:${NAME_AI}`)}</span>
                                    <div className="field">
                                        <input
                                            ref={refName}
                                            className="field-input"
                                            defaultValue={finalName}
                                            placeholder={t('name')}
                                        />
                                    </div>
                                </div>
                                <ItemButtonShape buttonShape={button_shape} onChangeValue={_handleChangeValue} />
                                <ItemPortalColor portalColor={finalColor} onChangeValue={_handleChangeValue} />
                                <ItemBackgroundVariant
                                    backgroundVariant={finalBackgroundVariant}
                                    onChangeValue={_handleChangeValue}
                                />
                                <ItemIcon portalIcon={finalIcon} onChangeValue={_handleChangeValue} />
                            </div>
                        )}
                        <div className="d-flex justify-end">
                            <ButtonSave
                                ref={refButtonSave}
                                title={t('save')}
                                wrapClass="v2-btn-main"
                                onSave={_handleSubmit}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatbotSettings;
