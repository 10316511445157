import { useReducer, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'app/modules/jobdetail/tabs/materials/components/Select';
import GdAutoComplete from 'app/modules/jobdetail/components/autocomplete';
import { LoadingForm } from 'app/modules/jobdetail/tabs/materials/components/Loading';
import IconGoto from 'assets/icon/IconGoto';
import IconMaterial from 'assets/icon/IconMaterial';
import IconQuantity from 'assets/icon/IconQuantity';
import IconDilution from 'assets/icon/IconDilution';
import IconMethod from 'assets/icon/IconMethod';
import IconArea from 'assets/icon/IconArea';
import IconTag from 'assets/icon/IconTag';
import IconMinus from 'assets/icon/IconMinus';
import IconPlus from 'assets/icon/IconPlus';
import ButtonSave from 'app/components/button/ButtonSave';
import { ADD_MATERIAL_STICKY, LIST_ITEM_MATERIAL } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { roundingNumber } from 'common/utils/NumberUtils';
import { reducer } from 'app/const/Reducer';
import { MATERIAL_STICKY_TYPES } from 'app/const/Sticky';

export default function EditForm({ isVisible, id, data, handleSubmit, closeForm, wrapClass, quickAdd = false }) {
    const { t } = useTranslation(['jobDetail']);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        material: null,
        quantity: null,
        unit: null,
        dilution: null,
        method: null,
        area: {
            quantity: null,
            id: null
        },
        selected: {
            locations: [],
            targets: []
        },
        items: {},
        rules: {}
    });

    const refDropdownLocations = useRef(null);
    const refDropdownTags = useRef(null);
    const refButtonSave = useRef(null);
    const refValueUnit = useRef(null);
    const refInputArea = useRef(null);

    const { isLoading, items, selected, rules: finalRules, method: finalMethod, area: finalArea } = state;
    const { materials, methods, units, areas, locations, targets } = items || {};

    const {
        area: areaRow = {},
        location: locationRow = {},
        method: methodRow = {},
        target: targetRow = {},
        unit: unitRow = {},
        material: materialRow = {}
    } = finalRules;

    useEffect(() => {
        isVisible && _getItemMaterial();
    }, [isVisible]);

    const _handleCloseForm = (e) => {
        e && e.stopPropagation();
        closeForm();
    };

    const _getItemMaterial = () => {
        clientQuery(
            LIST_ITEM_MATERIAL,
            {
                data: { inc: 'materials,methods,locations,targets,units,areas', limit: 0 },
                method: 'GET'
            },
            _getItemMaterialSuccess,
            _getListMaterialFailed
        );
    };

    const _getItemMaterialSuccess = (response) => {
        const { data: reponseData, rules } = response;
        const { materials: materialsItem, units: unitsItem, areas: areasItem } = reponseData;
        let materialSelected = materialsItem?.[0]?.id;
        let quantity = 0;
        let unitSelected = rules?.unit?.status ? unitsItem?.[0]?.id : '';
        let dilution = '';
        let methodSelected = '';
        let areaQuantity = 0;
        let areaId = rules?.area?.status ? areasItem?.[0]?.id : '';
        let locationSelected = [];
        let targetSelected = [];
        const locations = [];
        const targets = [];

        if (id) {
            const dataSelected = data.find((item) => item.id === id);
            materialSelected = dataSelected?.material?.id;
            quantity = dataSelected?.unit?.quantity;
            unitSelected = dataSelected?.unit?.id;
            dilution = dataSelected?.dilution;
            methodSelected = dataSelected?.method?.id;
            areaQuantity = dataSelected?.area?.quantity;
            areaId = dataSelected?.area?.id;
            locationSelected = dataSelected?.location?.items;
            targetSelected = dataSelected?.target?.items;
        }

        locationSelected.map((item) => {
            locations.push(item?.id);
        });

        targetSelected.map((item) => {
            targets.push(item?.id);
        });

        dispatchState({
            isLoading: false,
            items: { ...reponseData },
            rules: response.rules,
            material: materialSelected,
            quantity: quantity,
            unit: unitSelected,
            dilution: dilution,
            method: methodSelected,
            area: { quantity: areaQuantity, id: areaId },
            selected: {
                locations: locations,
                targets: targets
            }
        });
    };

    const _getListMaterialFailed = () => {
        dispatchState({
            isLoading: false
        });
    };

    const _handleChangeInput = (e, name) => {
        const newState = { ...state };
        const valueTarget = e.target.value;

        switch (name) {
            case 'unit':
                const finalValue = isNaN(valueTarget)
                    ? state.quantity
                    : valueTarget && Number(valueTarget) >= 0
                    ? roundingNumber(valueTarget)
                    : 0;
                newState.quantity = finalValue;
                refValueUnit.current.value = finalValue;
                break;
            case 'dilution':
                newState.dilution = e.target.value;
                break;
            case 'area':
                const finalValueArea = isNaN(valueTarget)
                    ? state.area.quantity
                    : valueTarget && Number(valueTarget) >= 0
                    ? roundingNumber(valueTarget)
                    : 0;

                newState.area.quantity = finalValueArea;
                refInputArea.current.value = finalValueArea;
                break;
            default:
                break;
        }
        dispatchState(newState);
    };

    const _handleIncrease = (e) => {
        e && e.stopPropagation();
        dispatchState((prev) => {
            const finalValue = parseFloat(parseFloat(prev.quantity || 0) + 1).toFixed(2);
            refValueUnit.current.value = finalValue;
            return {
                ...prev,
                quantity: finalValue
            };
        });
    };

    const _handleDecrease = (e) => {
        e && e.stopPropagation();
        dispatchState((prev) => {
            const finalValue =
                parseFloat(prev.quantity) - 1 > 0 ? parseFloat(parseFloat(prev.quantity) - 1).toFixed(2) : 0;
            refValueUnit.current.value = finalValue;
            return {
                ...prev,
                quantity: finalValue
            };
        });
    };

    const _onKeyDownCost = (event) => {
        const finalKeyCode = event.keyCode;
        const finalKey = event.key;
        const value = event.target.value;

        const valueKeyCode =
            event.ctrlKey ||
            event.altKey ||
            (47 < finalKeyCode && finalKeyCode < 58 && event.shiftKey == false) ||
            (95 < finalKeyCode && finalKeyCode < 106) ||
            finalKeyCode == 8 ||
            finalKeyCode == 9 ||
            (finalKeyCode > 34 && finalKeyCode < 40) ||
            finalKeyCode == 46;

        const finalValue = valueKeyCode || (finalKey === '.' && !value.concat('.').includes('..'));

        !finalValue && event.preventDefault();
    };

    const _handleOnSelect = (name, value) => {
        const newState = { ...state };
        switch (name) {
            case 'material':
                newState.material = value;
                break;
            case 'unit':
                newState.unit = value;
                break;
            case 'method':
                newState.method = newState.method === value ? '' : value;
                break;
            case 'area_id':
                newState.area.id = value;
                break;
            case 'locations':
                let locations = [...state.selected.locations];
                let locationUnChecked = true;
                locations = locations.filter((item) => {
                    if (item === value) {
                        locationUnChecked = false;
                    } else {
                        return item;
                    }
                });
                locationUnChecked && locations.push(value);
                newState.selected.locations = locations;
                break;
            case 'targets':
                let targets = [...state.selected.targets];
                let targetUnChecked = true;
                targets = targets.filter((item) => {
                    if (item === value) {
                        targetUnChecked = false;
                    } else {
                        return item;
                    }
                });
                targetUnChecked && targets.push(value);
                newState.selected.targets = targets;
                break;
            default:
                break;
        }
        dispatchState(newState);
    };

    const _handleRemoveTag = (name, value) => {
        const newState = { ...state };
        switch (name) {
            case 'locations':
                let locations = [...state.selected.locations];
                locations = locations.filter((item) => {
                    return item !== value;
                });
                newState.selected.locations = locations;
                break;
            case 'targets':
                let targets = [...state.selected.targets];
                targets = targets.filter((item) => {
                    return item !== value;
                });
                newState.selected.targets = targets;
                break;
            default:
                break;
        }
        dispatchState(newState);
    };

    const _handleRemoveAllTag = (name) => {
        const newState = { ...state };
        switch (name) {
            case 'locations':
                newState.selected.locations = [];
                break;
            case 'targets':
                newState.selected.targets = [];
                break;
            default:
                break;
        }
        dispatchState(newState);
    };

    const _handleSubmit = (e) => {
        e && e.preventDefault();
        const params = {
            material_id: state.material || '',
            quantity: state.quantity || null,
            dilution: state.dilution || null,
            measurement_unit_id: state.unit || '',
            method_id: state.method || '',
            area_id: finalArea?.id || '',
            area_quantity: finalArea?.quantity || null,
            location_ids: selected?.locations || [],
            target_ids: selected?.targets || []
        };
        quickAdd && refButtonSave.current.removeLoading();
        handleSubmit(id, params, items);
    };

    const _addSticky = (ids, type) => {
        clientQuery(ADD_MATERIAL_STICKY, {
            method: 'PUT',
            data: {
                ids: ids,
                type: MATERIAL_STICKY_TYPES[type]
            }
        });
    };

    const _renderForm = () => {
        if (isLoading) {
            return <LoadingForm />;
        }

        return (
            <div className="details-job">
                {_renderMaterials()}
                {_renderUnit()}
                {_renderDilution()}
                {_renderMethods()}
                {(locationRow.status || targetRow.status) && <div className="line" />}
                {_renderLocations()}
                {_renderTarget()}
                {areaRow.status && <div className="line" />}
                {_renderArea()}
            </div>
        );
    };

    return (
        <div className={wrapClass || 'container-column form-edit-template form-edit-material'}>
            <div className="header-modal">
                <div className="d-flex name-tabs">
                    <IconGoto />
                    <div className="title-h1 ml-2">
                        {!id ? t('jobDetail:add_material') : t('jobDetail:edit_material')}
                    </div>
                </div>
                <div className="d-flex">
                    <div
                        onClick={(e) => {
                            _handleCloseForm(e);
                        }}
                        className="v2-btn-default --transparent mr-3"
                        tabIndex="0"
                    >
                        {t('jobDetail:cancel')}
                    </div>
                    <ButtonSave ref={refButtonSave} onSave={_handleSubmit} />
                </div>
            </div>
            {_renderForm()}
        </div>
    );

    function _renderUnit() {
        if (!unitRow.status) {
            return false;
        }

        return (
            <div className="rows --sm">
                <div className="txt">
                    <IconQuantity />
                    <span className="flex-1 txt-ellipsis pr-1" title={unitRow.name}>
                        {unitRow.name}
                    </span>
                </div>
                <div className="details has-input field-every">
                    <div className="field-input field-number">
                        <span
                            onClick={(e) => {
                                _handleDecrease(e);
                            }}
                            className="v2-btn-default just-icon"
                            tabIndex="0"
                        >
                            <IconMinus />
                        </span>
                        <input
                            ref={refValueUnit}
                            type="text"
                            onKeyDown={_onKeyDownCost}
                            onBlur={(e) => {
                                _handleChangeInput(e, 'unit');
                            }}
                            defaultValue={state?.quantity || 0}
                        />
                        <span
                            onClick={(e) => {
                                _handleIncrease(e);
                            }}
                            className="v2-btn-default just-icon"
                            tabIndex="0"
                        >
                            <IconPlus />
                        </span>
                    </div>
                    <Select
                        name="unit"
                        classWrapper={'v2-dropdown'}
                        options={units}
                        selected={state?.unit || units?.[0]?.id}
                        onSelect={_handleOnSelect}
                        isPinToTop
                        onAddSticky={_addSticky}
                    />
                </div>
            </div>
        );
    }

    function _renderArea() {
        if (!areaRow.status) {
            return false;
        }

        return (
            <div className="rows --sm">
                <div className="txt">
                    <IconArea />
                    <span className="flex-1 txt-ellipsis pr-1" title={areaRow.name}>
                        {areaRow.name}
                    </span>
                </div>
                <div className="details has-input d-flex">
                    <input
                        ref={refInputArea}
                        type="text"
                        onBlur={(e) => {
                            _handleChangeInput(e, 'area');
                        }}
                        onKeyDown={_onKeyDownCost}
                        className="field-input field-number"
                        defaultValue={state?.area?.quantity || 0}
                    />
                    <Select
                        name="area_id"
                        classWrapper={'v2-dropdown'}
                        options={areas}
                        selected={state?.area?.id || areas?.[0]?.id}
                        onSelect={_handleOnSelect}
                        isPinToTop
                        onAddSticky={_addSticky}
                    />
                </div>
            </div>
        );
    }

    function _renderDilution() {
        return (
            <div className="rows --sm">
                <div className="txt">
                    <IconDilution />
                    <span className="flex-1 txt-ellipsis pr-1" title={t('jobDetail:dilution')}>
                        {t('jobDetail:dilution')}
                    </span>
                </div>
                <div className="details">
                    <input
                        onChange={(e) => {
                            _handleChangeInput(e, 'dilution');
                        }}
                        className="field-input w-100"
                        name="dilution"
                        type="text"
                        value={state?.dilution || ''}
                    />
                </div>
            </div>
        );
    }

    function _renderMethods() {
        if (!methodRow.status) {
            return false;
        }
        return (
            <div className="rows --sm">
                <div className="txt">
                    <IconMethod />
                    <span className="flex-1 txt-ellipsis pr-1" title={methodRow.name}>
                        {methodRow.name}
                    </span>
                </div>
                <Select
                    name="method"
                    classWrapper={'details v2-dropdown'}
                    options={finalMethod ? [{ id: '', name: '' }, ...methods] : methods}
                    selected={finalMethod}
                    onSelect={_handleOnSelect}
                    isPinToTop
                    onAddSticky={_addSticky}
                />
            </div>
        );
    }

    function _renderTarget() {
        if (!targetRow.status) {
            return false;
        }
        return (
            <div className="rows">
                <div className="txt">
                    <IconTag />
                    <span className="flex-1 txt-ellipsis pr-1" title={targetRow.name}>
                        {targetRow.name}
                    </span>
                </div>
                <GdAutoComplete
                    ref={refDropdownTags}
                    classWrapper={'details v2-dropdown'}
                    name="targets"
                    options={targets}
                    defaultValue={selected?.targets}
                    onSelect={_handleOnSelect}
                    removeTag={_handleRemoveTag}
                    removeAllTags={_handleRemoveAllTag}
                    isDropDownIcon
                    showClose={false}
                    isPinToTop
                    onAddSticky={_addSticky}
                />
            </div>
        );
    }

    function _renderLocations() {
        if (!locationRow.status) {
            return false;
        }
        return (
            <div className="rows">
                <div className="txt">
                    <IconTag />
                    <span className="flex-1 txt-ellipsis pr-1" title={locationRow.name}>
                        {locationRow.name}
                    </span>
                </div>
                <GdAutoComplete
                    ref={refDropdownLocations}
                    classWrapper={'details v2-dropdown'}
                    name="locations"
                    options={locations}
                    defaultValue={selected?.locations}
                    onSelect={_handleOnSelect}
                    removeTag={_handleRemoveTag}
                    removeAllTags={_handleRemoveAllTag}
                    isDropDownIcon
                    showClose={false}
                    isPinToTop
                    onAddSticky={_addSticky}
                />
            </div>
        );
    }

    function _renderMaterials() {
        if (!materialRow.status) return null;
        return (
            <div className="rows --sm">
                <div className="txt">
                    <IconMaterial />
                    <span className="flex-1 txt-ellipsis pr-1" title={materialRow.name}>
                        {materialRow.name}
                    </span>
                </div>
                <Select
                    name="material"
                    classWrapper={'details v2-dropdown'}
                    options={materials}
                    selected={state?.material}
                    onSelect={_handleOnSelect}
                    isPinToTop
                    onAddSticky={_addSticky}
                />
            </div>
        );
    }
}
