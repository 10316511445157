import { React, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SETTINGS_PLANS, SETTINGS_INVOICES } from 'app/config/routes';
import { GET_ACCOUNT_DETAIL } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';
import { formatDateLocal } from 'common/utils/DateUtils';
import { GORILLA_DESK_MY_SUBSCRIPTION } from 'app/const/Route';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { addBranchPath } from 'app/const/Branch';
import CardDate from 'app/components/payment/components/CardDate';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import { ACCESS_ACCOUNT } from 'app/const/Permissions';
import { getTranslatedPlanName } from './utils';

const IconUpgrade = loadable(() => import('assets/icon/IconUpgrade'));
const IconSingleStep = loadable(() => import('assets/icon/IconSingleStep'));
const AccountLoading = loadable(() => import('./components/AccountLoading'));
const IconClose = loadable(() => import('assets/icon/IconClose'));
const IconAlert = loadable(() => import('assets/icon/IconAlert'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

const SettingAccount = () => {
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        accountData: {},
        isOpen: true
    });
    const { t } = useTranslation(['setting']);
    const {
        accountData: { account, plan, period, expire }
    } = state;
    const { company, profile } = useSelector(({ auth }) => auth.user);

    const getFormatDate = company.date_format;
    const planRetrieve = profile.plan;

    const dateLastInvoice = formatDateLocal(account?.last_date, getFormatDate);
    const dateNextInvoice = formatDateLocal(account?.next_date, getFormatDate);
    const planInfo = getTranslatedPlanName({ plan: plan || {}, period });
    const isExpired = expire === 1 || false;
    const isFreeze = plan?.freeze || false;
    const planId = +account?.plan_id || 0;
    const planCancel = account?.plan_cancel || 0;
    const { isTrial, isProPlan, isBasicPlan, isGrowthPlan } = getPlanUser(profile);

    useEffect(() => {
        clientQuery(GET_ACCOUNT_DETAIL, { method: 'GET' }, _handleGetDataSuccess, _handleGetDataFail);
    }, []);

    const _handleGetDataSuccess = ({ data }) => {
        dispatchState({
            accountData: data,
            isLoading: false
        });
    };

    const _handleGetDataFail = (err) => {
        checkAccessFail(err, ACCESS_ACCOUNT);
    };

    const _handleOpenLink = (link) => {
        const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const _handleClose = () => {
        dispatchState({ isOpen: false });
    };

    return (
        <>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                {state.isLoading ? (
                    <AccountLoading />
                ) : (
                    <div className="contents-pages page-account container-column">
                        {isExpired && state.isOpen && (
                            <div className="alert --danger">
                                <p className="alert__description">
                                    {t(
                                        'setting:your_free_trial_has_expired_please_subscribed_to_a_plan_to_continue_using_gorilladesk'
                                    )}
                                </p>
                                <div onClick={_handleClose} className="alert__btn svg-white-stroke">
                                    <IconClose />
                                </div>
                            </div>
                        )}
                        <div className="title-header">
                            <h1 className="title-header__label">{t('setting:your_gorilladesk_account')}</h1>
                        </div>
                        <div className="scrolls box-auto">
                            <div className="setting-wrap page-wrapper">
                                {isFreeze && (
                                    <div className="account-types">
                                        <div className="account-types__title">
                                            <IconAlert isHasColor />
                                            <span className="account-text">{t('setting:freeze')}</span>
                                        </div>
                                        <div className="account-types__description">
                                            {t(
                                                'setting:your_data_is_stored_securely_select_another_plane_to_resume_full_access_to_your_account'
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className="boxs">
                                    <div className="boxs__title flexcenter">
                                        {!account && !isTrial && (
                                            <>
                                                <p className="fw-600">{t('setting:current_plan')}</p>:
                                                <span className="pl-1 fw-600">{t('setting:expired')}</span>
                                            </>
                                        )}
                                        {isExpired && (
                                            <>
                                                <p className="fw-600">{`${t('setting:current_plan')} ${t(
                                                    'setting:cancelled'
                                                )}`}</p>
                                                :<span className="pl-1 fw-600">{planInfo}</span>
                                            </>
                                        )}
                                        {!isExpired && !!planId && !!planCancel && (
                                            <>
                                                <p className="fw-600">{`${t('setting:current_plan')} ${t(
                                                    'setting:cancelled'
                                                )}`}</p>
                                                :<span className="pl-1 fw-600">{planInfo}</span>
                                            </>
                                        )}
                                        {planId > 0 && !planCancel && (
                                            <>
                                                <p className="fw-600">{`${t('setting:current_plan')}`}</p>:
                                                <span className="pl-1 fw-600">{planInfo}</span>
                                            </>
                                        )}
                                        {((account &&
                                            planRetrieve.status === 'expired' &&
                                            planRetrieve.name === 'free_trial') ||
                                            isTrial) && (
                                            <>
                                                <p className="fw-600">{t('setting:current_plan')}</p>:
                                                <span className="pl-1 fw-600">{t('setting:free_trial')}</span>
                                            </>
                                        )}
                                        {isFreeze && (
                                            <>
                                                <p className="fw-600">{t('setting:current_plan')}</p>:
                                                <span className="pl-1 fw-600">{t('setting:expired')}</span>
                                            </>
                                        )}
                                    </div>
                                    <div className="boxs__contents">
                                        {isExpired && (
                                            <p className="word-break red-active">
                                                {t('setting:your_plan_needs_to_be_renewed')}
                                            </p>
                                        )}
                                        {!isExpired && !!planId && !!planCancel && (
                                            <p className="word-break">{t('setting:no_recurring_charges')}</p>
                                        )}
                                        {planId > 0 && !planCancel && (
                                            <p className="word-break">
                                                {t('setting:your_next_charge_is')} ${plan?.price} {t('setting:on')}{' '}
                                                {dateNextInvoice}
                                            </p>
                                        )}
                                        {!account && <p className="word-break">{t('setting:no_recurring_charges')}</p>}
                                        {isFreeze && <p className="word-break">{t('setting:no_recurring_charges')}</p>}
                                    </div>
                                    <Link to={addBranchPath(SETTINGS_PLANS)} className="boxs__more">
                                        <span className="txt-ellipsis">
                                            {t('setting:see_plans_and_activate_account')}
                                        </span>
                                        <span className="svg-chevron purple">
                                            <IconSingleStep />
                                        </span>
                                    </Link>
                                </div>
                                <div className="boxs">
                                    <span className="boxs__title fw-600">{t('setting:credit_card')}</span>
                                    <div className="boxs__contents">
                                        <span className="txt-ellipsis">
                                            {t(!account ? 'no_credit_card_on_file' : 'your_credit_card_on_file_is')}
                                        </span>
                                        {account && (
                                            <div className="info-card">
                                                <div className="card-number">
                                                    <p className="card-number__label">{t('setting:stripe')}</p>
                                                    <div className="number">
                                                        <p className="number__dots">••••</p>
                                                        <p className="number__digit txt-ellipsis">{account?.number}</p>
                                                    </div>
                                                    <CardDate
                                                        className="date"
                                                        exp_month={account?.expire_month}
                                                        exp_year={account?.expire_year}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <Link
                                        to={addBranchPath(!isFreeze && !isTrial ? SETTINGS_INVOICES : SETTINGS_PLANS)}
                                        className="boxs__more"
                                    >
                                        <span className="txt-ellipsis">
                                            {!account && t('setting:please_choose_a_plan_to_reactivate_your_account')}
                                            {isExpired && t('setting:change_your_credit_card_and_billing_information')}
                                            {(isExpired || (!!planId && !!planCancel)) &&
                                                t('setting:please_choose_a_plan_to_reactivate_your_account')}
                                            {planId > 0 &&
                                                !planCancel &&
                                                t('setting:change_your_credit_card_and_billing_information')}
                                            {account &&
                                                planRetrieve.status === 'expired' &&
                                                planRetrieve.name === 'free_trial' &&
                                                t('setting:please_choose_a_plan_to_reactivate_your_account')}
                                            {isFreeze && t('setting:please_choose_a_plan_to_reactivate_your_account')}
                                        </span>
                                        <span className="svg-chevron purple">
                                            <IconSingleStep />
                                        </span>
                                    </Link>
                                </div>
                                <div className="boxs">
                                    <span className="boxs__title fw-600">{t('setting:last_invoice')}</span>

                                    <div className="boxs__contents black-3">
                                        {!!account && !!planId ? (
                                            <>
                                                <span className="plans mr-1">
                                                    <IconUpgrade isFillWhite />
                                                    <span className="txt-ellipsis">{planInfo}</span>
                                                </span>
                                                <span className="txt-ellipsis">
                                                    {t('setting:on')} {dateLastInvoice}
                                                </span>
                                            </>
                                        ) : (
                                            <span className="txt-ellipsis">{t('setting:no_previous_invoices')}</span>
                                        )}
                                    </div>

                                    {(isProPlan || isBasicPlan || isGrowthPlan) && (
                                        <Link to={addBranchPath(SETTINGS_INVOICES)} className="boxs__more">
                                            <span className="txt-ellipsis">{t('setting:see_all_past_invoices')}</span>
                                            <span className="svg-chevron purple">
                                                <IconSingleStep />
                                            </span>
                                        </Link>
                                    )}
                                </div>
                                {planId > 0 && !planCancel ? (
                                    <div className="boxs last-boxs">
                                        <span className="boxs__title fw-600">
                                            {t('setting:need_to_freeze_or_stop_your_subscription')}
                                        </span>
                                        <div className="boxs__contents">
                                            {t('setting:please_complete_the_form_below')}
                                        </div>
                                        <div
                                            onClick={() => {
                                                _handleOpenLink(GORILLA_DESK_MY_SUBSCRIPTION);
                                            }}
                                            className="boxs__more"
                                        >
                                            <span className="txt-ellipsis">
                                                {t('setting:subscription_change_request')}
                                            </span>
                                            <span className="svg-chevron purple">
                                                <IconSingleStep />
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SettingAccount;
