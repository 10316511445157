import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const getGridTableSentricon = (actionsStatus = COMMON.INSTALL_DATE.concat(' ', COMMON.DESC)) => {
    const HEADERS = {
        branch_code: { title: 'corteva_css_code', style: 'col col-md' },
        location_code: { title: 'site_code', style: 'col col-sm' },
        customer: { title: 'site_owner_name', style: 'col col-lg' },
        service_address: { title: 'street_address', style: 'col col-address' },
        service_address_city: { title: 'city', style: 'col col-md' },
        service_address_state: { title: 'state', style: 'col col-sm' },
        service_address_zip: { title: 'zip', style: 'col col-sm' },
        install_type: { title: 'install_type', style: 'col col-lg' },
        linear_feet: { title: 'linear_feet', style: 'col col-sm' },
        install_date: {
            title: 'install_date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: { title: 'status', style: 'col col-sm' },
        reactive_date: { title: 'reactive_date', style: 'col col-md' },
        cancellation_date: { title: 'cancellation_date', style: 'col col-md' }
    };
    const COLUMNS = [
        { id: 'branch_code', title: 'corteva_css_code', status: true },
        { id: 'location_code', title: 'site_code', status: true },
        { id: 'customer', title: 'site_owner_name', status: true },
        { id: 'service_address', title: 'street_address', status: true },
        { id: 'service_address_city', title: 'city', status: true },
        { id: 'service_address_state', title: 'state', status: true },
        { id: 'service_address_zip', title: 'zip', status: true },
        { id: 'install_type', title: 'install_type', status: true },
        { id: 'linear_feet', title: 'linear_feet', status: true },
        { id: 'install_date', title: 'install_date', status: true },
        { id: 'status', title: 'status', status: true },
        { id: 'reactive_date', title: 'reactive_date', status: true },
        { id: 'cancellation_date', title: 'cancellation_date', status: true }
    ];
    const CONTENTS = {
        branch_code: { title: 'corteva_css_code', style: 'col col-md' },
        location_code: { title: 'site_code', style: 'col col-sm' },
        customer: { title: 'site_owner_name', style: 'col col-lg', type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO },
        service_address: {
            title: 'street_address',
            style: 'col col-address',
            type: LIST_TABLE_ITEM_TYPE.SERVICE_ADDRESS
        },
        service_address_city: { title: 'service_address_city', style: 'col col-md' },
        service_address_state: { title: 'service_address_state', style: 'col col-sm' },
        service_address_zip: { title: 'service_address_zip', style: 'col col-sm' },
        install_type: { title: 'install_type', style: 'col col-lg', type: LIST_TABLE_ITEM_TYPE.INSTALL_TYPE },
        linear_feet: { title: 'linear_feet', style: 'col col-sm' },
        install_date: { title: 'install_date', style: 'col col-md' },
        status: { title: 'status', style: 'col col-sm', type: LIST_TABLE_ITEM_TYPE.STATUS },
        reactive_date: { title: 'reactive_date', style: 'col col-md' },
        cancellation_date: { title: 'cancellation_date', style: 'col col-md' }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...HEADERS }),
        columns: COLUMNS,
        contentConfig: CONTENTS
    };
};
