import { AUTH_CONFIRM_TOKEN, AUTH_LOGIN, AUTH_PASSWORD_FORGOT, AUTH_SIGN_UP, SIGN_UP_SUCCESS } from 'app/const/Route';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import AddonsBooking from 'app/modules/addons/booking';
import AddonsBookingSettings from 'app/modules/addons/booking/settings';
import AddonsCommissionTracking from 'app/modules/addons/commissionTracking';
import AddonsDevice from 'app/modules/addons/device';
import AddonsDocument from 'app/modules/addons/documents';
import AvailableDocuments from 'app/modules/addons/documents/AvailableDocuments';
import LibraryDocuments from 'app/modules/addons/documents/LibraryDocuments';
import AddonsInboxEmail from 'app/modules/addons/email';
import AddonsGpsTracking from 'app/modules/addons/gps';
import AddonsLayout from 'app/modules/addons/layout/AddonsLayout';
import AddonsMaterialUsage from 'app/modules/addons/material';
import AddonsMultiUnit from 'app/modules/addons/multiUnit';
import MultiTrackingManager from 'app/modules/addons/multiUnit/components/MultiTrackingManager';
import AddonQuickBooksSync from 'app/modules/addons/quickbooks';
import AddonQuickbooksLog from 'app/modules/addons/quickbooks/log';
import AddonsReviewEngine from 'app/modules/addons/review';
import AddonsRouting from 'app/modules/addons/routing';
import AddonsSms from 'app/modules/addons/sms';
import AddonsSquare from 'app/modules/addons/square';
import AddonsStripe from 'app/modules/addons/stripe';
import AddonsTrigger from 'app/modules/addons/trigger';
import AddonsZapier from 'app/modules/addons/zapier';
import AddonsTimeWindow from 'app/modules/addons/timeWindow';
import ForgotPassword from 'app/modules/auth/fogotPassword';
import Login from 'app/modules/auth/login';
import Signup from 'app/modules/auth/signup';
import Calendar from 'app/modules/calendar';
import CommissionLayout from 'app/modules/commission/layouts/CommissionLayout';
import CustomerAccount from 'app/modules/customer/detail/accounts';
import CustomerContacts from 'app/modules/customer/detail/contacts';
import CustomerCredits from 'app/modules/customer/detail/credits';
import CustomerDocument from 'app/modules/customer/detail/documents';
import CustomerEstimates from 'app/modules/customer/detail/estimates';
import CustomerInvoices from 'app/modules/customer/detail/invoices';
import CustomerJobs from 'app/modules/customer/detail/jobs';
import CustomerLocations from 'app/modules/customer/detail/locations';
import CustomerPayments from 'app/modules/customer/detail/payments';
import CustomerStatements from 'app/modules/customer/detail/statements';
import CustomerTimeLine from 'app/modules/customer/detail/timeline';
import CustomerImport from 'app/modules/customer/import';
import CustomerImported from 'app/modules/customer/import/Imported';
import CustomerLayout from 'app/modules/customer/layouts/CustomerLayout';

import EmailInbox from 'app/modules/inbox';
import Lead from 'app/modules/lead';
import ReportAccountsAging from 'app/modules/report/accountsaging';
import ReportCredit from 'app/modules/report/credits';
import ReportCustomersWoActiveJobs from 'app/modules/report/customerswoactivejobs';
import ReportDocument from 'app/modules/report/documents';
import EmailHistory from 'app/modules/report/emailhistory';
import ReportEstimate from 'app/modules/report/estimates';
//Report
import ReportInvoice from 'app/modules/report/invoices';
import ReportLayout from 'app/modules/report/layouts/ReportLayout';
import ReportLocationsWoActiveJobs from 'app/modules/report/locationswoactivejobs';
import MaterialUse from 'app/modules/report/materialuse';
import ReportNewCustomers from 'app/modules/report/newcustomers';
import ReportPayment from 'app/modules/report/payments';
import ReportPaymentCollected from 'app/modules/report/paymentscollected';
import ReportRecurringInvoice from 'app/modules/report/recurringinvoices';
import RevenueByClient from 'app/modules/report/revenuebyclient';
import RevenueByItem from 'app/modules/report/revenuebyitem';
import RevenueByService from 'app/modules/report/revenuebyservice';
import RevenueBySource from 'app/modules/report/revenuebysource';
import RevenueByStaff from 'app/modules/report/revenuebystaff';
import RevenueByTags from 'app/modules/report/revenuebytags';
import ReportSalesForecast from 'app/modules/report/salesforecast';
import ReportSalesTaxSummary from 'app/modules/report/salestaxsummary';
import ReportServiceLookup from 'app/modules/report/servicelookup';
import ReportSubscription from 'app/modules/report/subscriptions';
import ReportTotalSales from 'app/modules/report/totalsales';
import Review from 'app/modules/review';
import SettingsAccount from 'app/modules/settings/account';
import SettingAddService from 'app/modules/settings/addservice';
import SettingsBroadcast from 'app/modules/settings/broadcast';
import SettingsCompany from 'app/modules/settings/company';
import SettingsCustoms from 'app/modules/settings/customs';
import SettingEditService from 'app/modules/settings/editservice';
import SettingsEmailInbox from 'app/modules/settings/emailinbox';
import SettingsEmailInboxDetails from 'app/modules/settings/emailinbox/details';
import SettingsEstimate from 'app/modules/settings/estimate';
import SettingsInvoice from 'app/modules/settings/invoice';
import SettingsInvoices from 'app/modules/settings/invoices';
import SettingsInvoiceDetail from 'app/modules/settings/invoices/detail';
import SettingLayout from 'app/modules/settings/layouts/SettingLayout';
import SettingsLineItems from 'app/modules/settings/lineitems';
import SettingsPaymentMethods from 'app/modules/settings/paymentmethods';
//// Settings
import AddonsPortal from 'app/modules/addons/portal';
import LoginSocial from 'app/modules/auth/login/loginSocial';
import CustomerPortal from 'app/modules/customer/detail/portal';
import SettingsPlans from 'app/modules/settings/plans';
import SettingsSchedules from 'app/modules/settings/schedules';
import SettingsService from 'app/modules/settings/service';
import SettingsSources from 'app/modules/settings/sources';
import SettingsSystems from 'app/modules/settings/systems';
import SettingsTags from 'app/modules/settings/tags';
import SettingsTaxes from 'app/modules/settings/taxes';
import SettingsPaperwork from 'app/modules/settings/templatespage';
import SettingsTiles from 'app/modules/settings/tiles';
import SettingsUsers from 'app/modules/settings/users';
import Dashboard from 'app/modules/dashboard';
import {
    ADDONS,
    ADDONS_BOOKING,
    ADDONS_BOOKING_SETTINGS,
    ADDONS_COMMISSION_TRACKING,
    ADDONS_DEVICE_TRACKING,
    ADDONS_DOCUMENTS,
    ADDONS_DOCUMENTS_AVAILABLE,
    ADDONS_DOCUMENTS_LIBRARY,
    ADDONS_GPS_TRACKING,
    ADDONS_INBOX_EMAIL,
    ADDONS_MATERIAL_USAGE,
    ADDONS_MULTI_UNIT,
    ADDONS_MULTI_UNIT_MANAGER,
    ADDONS_PORTAL,
    ADDONS_QUICKBOOKS_SYNC,
    ADDONS_QUICKBOOKS_SYNC_LOG,
    ADDONS_REVIEW_ENGINE,
    ADDONS_ROUTING,
    ADDONS_SMS,
    ADDONS_SQUARE,
    ADDONS_SQUARE_SUBSCRIPTION,
    ADDONS_STRIPE,
    ADDONS_STRIPE_SUBSCRIPTION,
    ADDONS_TRIGGER,
    ADDONS_ZAPIER,
    COMMISSIONS,
    CUSTOMERS,
    CUSTOMERS_ACCOUNT,
    CUSTOMERS_CONTACTS,
    CUSTOMERS_CREDITS,
    CUSTOMERS_DETAIL,
    CUSTOMERS_DETAILS_PATHS,
    CUSTOMERS_DOCUMENTS,
    CUSTOMERS_ESTIMATES,
    CUSTOMERS_IMPORT,
    CUSTOMERS_IMPORTED,
    CUSTOMERS_IMPORT_PATHS,
    CUSTOMERS_INVOICES,
    CUSTOMERS_JOBS,
    CUSTOMERS_LOCATIONS,
    CUSTOMERS_PAYMENTS,
    CUSTOMERS_PORTAL,
    CUSTOMERS_STATEMENTS,
    // DRIP,
    // DRIP_CAMPAIGN,
    // DRIP_CAMPAIGN_DETAIL,
    // DRIP_CAMPAIGN_REPORT,
    // DRIP_PATHS,
    // DRIP_SMART_LIST,
    // DRIP_SMART_LIST_DETAIL,
    // DRIP_TEMPLATE,
    // DRIP_TEMPLATE_DETAIL,
    // DRIP_UN_SUBSCRIBED,
    INBOX_HOME,
    INVOICE_DETAIL,
    REPORT_ACCOUNTS_AGING,
    REPORT_CREDIT,
    REPORT_CUSTOMERS_WO_ACTIVE_JOBS,
    REPORT_DOCUMENTS,
    REPORT_EMAIL_LOG,
    REPORT_ESTIMATES,
    REPORT_INVOICE,
    REPORT_LEADS,
    REPORT_LOCATION_WO_ACTIVE_JOBS,
    REPORT_MATERIAL_USE,
    REPORT_NEW_CUSTOMERS,
    REPORT_PAYMENT,
    REPORT_PAYMENTS_COLLECTED,
    REPORT_RECURRING_INVOICES,
    REPORT_REVENUE_CLIENT,
    REPORT_REVENUE_ITEM,
    REPORT_REVENUE_SERVICE,
    REPORT_REVENUE_SOURCE,
    REPORT_REVENUE_STAFF,
    REPORT_REVENUE_TAGS,
    REPORT_SALES_FORECAST,
    REPORT_SALES_TAX_SUMMARY,
    REPORT_SERVICES_LOOKUP,
    REPORT_SUBSCRIPTIONS,
    REPORT_SQUARE_SUBSCRIPTIONS,
    REPORT_TOTAL_SALES,
    REVIEWS,
    SETTINGS_ACCOUNT,
    SETTINGS_BROADCAST,
    SETTINGS_COMPANY,
    SETTINGS_CUSTOMS,
    SETTINGS_EMAIL_INBOX,
    SETTINGS_EMAIL_INBOX_DETAIL,
    SETTINGS_ESTIMATE,
    SETTINGS_INVOICE,
    SETTINGS_INVOICES,
    SETTINGS_LINE_ITEMS,
    SETTINGS_PAYMENT_METHODS,
    SETTINGS_PLANS,
    SETTINGS_SCHEDULES,
    SETTINGS_SERVICE,
    SETTINGS_SERVICE_ADD,
    SETTINGS_SERVICE_EDIT,
    SETTINGS_SOURCES,
    SETTINGS_SYSTEMS,
    SETTINGS_TAGS,
    SETTINGS_TAXES,
    SETTINGS_PAPERWORK,
    SETTINGS_TILES,
    SETTINGS_USERS,
    ADDONS_TIME_WINDOW,
    CALENDAR,
    SETTINGS_MAIN_PATH,
    REPORT_MAIN_PATH,
    SETTINGS_TEMPLATES_MANAGER,
    REVIEWS_LIKELY,
    REVIEWS_SERVICE,
    REPORT_LEADS_INBOUND,
    REPORT_LEADS_BOOKING,
    SETTINGS_TEMPLATES_TODO,
    SETTINGS_TEMPLATES_NOTES,
    ADDONS_API_KEYS,
    ADDONS_VOIP,
    ADDONS_VOIP_MANAGE_GROUP,
    ADDONS_VOIP_MANAGE_PERSONAL,
    ADDONS_VOIP_PLAN,
    ADDONS_VOIP_ACTIVE_USERS,
    DASHBOARD,
    ADDONS_OPPORTUNITY,
    ADDONS_OPPORTUNITY_MANAGE,
    ADDONS_MULTIPLE_BRANCH,
    ADDONS_MULTIPLE_BRANCH_PLAN,
    ADDONS_MULTIPLE_BRANCH_MANAGE,
    ADDONS_VOIP_HOLD,
    ADDONS_OPPORTUNITY_PERMISSIONS,
    TRACKER,
    REPORT_TRACKER,
    ADDONS_WORK_LOG,
    ADDONS_WORK_LOG_MANAGE,
    ADDONS_SMARTVIEW,
    ADDONS_BLOCKED_NUMBERS,
    ADDONS_CUSTOM_FIELDS,
    ADDONS_CUSTOM_FIELDS_MANAGE,
    ADDONS_CUSTOM_FIELDS_PERMISSIONS,
    SETTINGS_TEMPLATES_SMS,
    REFERRAL,
    ADDONS_MAIN_JOB_STATUS,
    ADDONS_JOB_STATUS_PERMISSION,
    ADDONS_JOB_STATUS_MANAGE,
    ADDONS_ESTIMATE,
    ACCOUNT_MAIN_PATH,
    ACCOUNT_PLANS_THANKS,
    ACCOUNT_PLANS_UPGRADE,
    ACCOUNT_PLANS_DOWNGRADE,
    SETTINGS_TEMPLATES_ESTIMATE,
    ADDONS_DOCUMENTS_MANAGE,
    REPORT_NEW_JOB,
    ADDONS_VOIP_VOICEMAIL_DROP,
    JOB_MAGNET_MANAGE,
    BEST_AVAILABLE_TIME_MANAGE,
    ADDONS_SMARTVIEW_PERMISSION,
    REPORT_DEVICE_TRACKING,
    ADDONS_CHAT_BOT_CONTENT,
    ADDONS_AI,
    ADDONS_CHAT_BOT_SETTINGS,
    ADDONS_CHAT_BOT_REPORT,
    ADDONS_CHAT_BOT_TRAIN_AI,
    ADDONS_CHAT_BOT_CUSTOM_ANSWER,
    PROFILE_AUTHENTICATION,
    ONBOARDING,
    ADDONS_SERVICE_PLAN,
    SETTINGS_SERVICE_PLAN,
    SETTINGS_SERVICE_PLAN_ADD,
    SETTINGS_SERVICE_PLAN_EDIT,
    ADDONS_PORTAL_WIDGET,
    ADDONS_SENTRICON_MANAGE,
    REPORT_SENTRICON
} from './routes';
import StripeSubscription from 'app/modules/addons/stripe/StripeSubscription';
import SquareSubscription from 'app/modules/addons/square/SquareSubscription';
import AddonsAPIKeys from 'app/modules/addons/apiKey';
import AuthenticatePages from 'AuthenticatePages';
import SettingsTemplatesManager from 'app/modules/settings/templatesManager';
import SignupSuccess from 'app/modules/auth/signup/components/SignupSuccess';
import TodoListManager from 'app/modules/settings/templatesManager/components/todoList';
import NotesTemplate from 'app/modules/settings/templatesManager/components/notes';
import { AddonsVOIP } from 'app/modules/addons/voip';
import { VOIPAddonsManage } from 'app/modules/addons/voip/components/VOIPAddonsManage';
import { VOIPAddonsPlan } from 'app/modules/addons/voip/components/plan/VOIPAddonsPlan';
import { ActiveVOIPUsers } from 'app/modules/addons/voip/components/activeUsers';
import { ACCESS_ADDONS, ACCESS_REPORT_TAB, ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import AddonsOpportunity from 'app/modules/addons/opportunity';
import AddonsOpportunityManage from 'app/modules/addons/opportunity/Manager';
import AddonsMultipleBranch from 'app/modules/addons/multipleBranch';
import MultipleBranchPlan from 'app/modules/addons/multipleBranch/activePlan';
import MultipleBranchManage from 'app/modules/addons/multipleBranch/manage';
import VOIPAddonsHold from 'app/modules/addons/voip/components/holdMusic';
import AddonsOpportunityPermissions from 'app/modules/addons/opportunity/Permissions';
import Tracker from 'app/modules/tracker';
import ReportTracker from 'app/modules/report/tracker';
import AddonsTracker from 'app/modules/addons/tracker';
import WorkLogManage from 'app/modules/addons/tracker/WorkLogManage';
import BlockNumbers from 'app/modules/addons/components/blockNumbers';
import AddonSmartView from 'app/modules/addons/smartView';
import AddonsCustomFields from 'app/modules/addons/customFields';
import CustomFieldsPermissions from 'app/modules/addons/customFields/permissions';
import CustomFieldsManage from 'app/modules/addons/customFields/manage';
import SMSTemplates from 'app/modules/settings/templatesManager/components/sms';
import TaxOverride from 'app/modules/settings/globalOverride/TaxOverride';
import ItemOverride from 'app/modules/settings/globalOverride/ItemOverride';
import { LIST_TOOLTIP } from 'app/const/Settings';
import AddonsJobStatus from 'app/modules/addons/jobStatus';
import JobStatusManage from 'app/modules/addons/jobStatus/manage';
import { PERMISSIONS_JOB_STATUS } from 'app/const/addons';
import AddonsEstimate from 'app/modules/addons/estimate';
import ThanksPage from 'app/modules/settings/plans/thanksPage';
import Referral from 'app/modules/referral';
import EstimatesTemplate from 'app/modules/settings/templatesManager/components/estimates';
import ManageDocuments from 'app/modules/addons/documents/ManageDocuments';
import ReportNewJob from 'app/modules/report/newjob';
import VoicemailDrop from 'app/modules/addons/voip/components/voicemailDrop';
import JobMagnetManage from 'app/modules/addons/advanceRouting/magnet';
import AddonsBestAvailableTime from 'app/modules/addons/advanceRouting/bestAvailableTime';
import ReportDeviceTracking from 'app/modules/report/deviceTracking';
import ChatbotLayout from 'app/modules/addons/chatbot/layouts/AddonsChatBoxLayout';
import AddonsChatBotAI from 'app/modules/addons/chatbot';
import ChatbotSettings from 'app/modules/addons/chatbot/chatbotSettings';
import ChatbotCustomerAnswer from 'app/modules/addons/chatbot/chatbotCustomAnswer';
import ChatbotReport from 'app/modules/addons/chatbot/report';
import { ADDONS_CHAT_BOT_MENU_OPTIONS } from 'app/modules/addons/chatbot/constants';
import ChatbotTrainAI from 'app/modules/addons/chatbot/trainAI';
import ChatbotContent from 'app/modules/addons/chatbot/chatbotContent/ChatbotContent';
import ProfileAuthLayout from 'app/modules/profileAuthentication/layout';
import OnBoarding from 'app/modules/onBoarding';
import SmartViewPermission from 'app/modules/addons/smartView/permissions';
import AddonServicePlan from 'app/modules/addons/servicePlan';
import SettingsServicePlan from 'app/modules/settings/servicePlan';
import SettingAddServicePlan from 'app/modules/settings/addServicePlan';
import PortalWidgetSetting from 'app/modules/addons/portal/widget';
import SentriconManage from 'app/modules/addons/device/sentricon';
import ReportSentricon from 'app/modules/report/sentricon';

/**
 * This const was check condition redirect to Calendar
 * on file "src\app\modules\notFoundPage\index.js" when authenticated
 */
export const AUTHENTICATE_ROUTES = [
    {
        path: '/auth',
        component: AuthenticatePages,
        routes: [
            { title: 'login', exact: true, path: AUTH_LOGIN, component: Login },
            { title: 'forgot_password', exact: true, path: AUTH_PASSWORD_FORGOT, component: ForgotPassword },
            { title: 'sign_up', exact: true, path: AUTH_SIGN_UP, component: Signup },
            { title: 'social', exact: true, path: AUTH_CONFIRM_TOKEN, component: LoginSocial },
            { title: 'trial_success', exact: true, path: SIGN_UP_SUCCESS, component: SignupSuccess }
        ]
    }
];

// FOR NEW CONFIG //
export const ROUTES_CONFIG = [
    { title: 'calendar', path: CALENDAR, component: Calendar, exact: false },
    { title: '', path: INBOX_HOME, component: EmailInbox, exact: false },
    {
        title: 'setting_plans_thanks',
        path: ACCOUNT_MAIN_PATH,
        component: SettingLayout,
        routes: [
            { title: 'setting_plans_thanks', exact: true, path: ACCOUNT_PLANS_THANKS, component: ThanksPage },
            { title: 'setting_plans_thanks', exact: true, path: ACCOUNT_PLANS_UPGRADE, component: ThanksPage },
            { title: 'setting_plans_thanks', exact: true, path: ACCOUNT_PLANS_DOWNGRADE, component: ThanksPage }
        ]
    },
    {
        path: [CUSTOMERS, ...CUSTOMERS_DETAILS_PATHS, ...CUSTOMERS_IMPORT_PATHS],
        component: CustomerLayout,
        exact: true,
        isMainRoute: true,
        routes: [
            { title: 'customer_timeline', exact: true, path: CUSTOMERS_DETAIL, component: CustomerTimeLine },
            { title: 'customer_credits', exact: true, path: CUSTOMERS_CREDITS, component: CustomerCredits },
            { title: 'customer_contacts', exact: true, path: CUSTOMERS_CONTACTS, component: CustomerContacts },
            { title: 'customer_estimates', exact: true, path: CUSTOMERS_ESTIMATES, component: CustomerEstimates },
            { title: 'customer_locations', exact: true, path: CUSTOMERS_LOCATIONS, component: CustomerLocations },
            { title: 'customer_statements', exact: true, path: CUSTOMERS_STATEMENTS, component: CustomerStatements },
            { title: 'customer_account', exact: true, path: CUSTOMERS_ACCOUNT, component: CustomerAccount },
            { title: 'customer_invoices', exact: true, path: CUSTOMERS_INVOICES, component: CustomerInvoices },
            { title: 'customer_documents', exact: true, path: CUSTOMERS_DOCUMENTS, component: CustomerDocument },
            { title: 'customer_payments', exact: true, path: CUSTOMERS_PAYMENTS, component: CustomerPayments },
            { title: 'customer_jobs', exact: true, path: CUSTOMERS_JOBS, component: CustomerJobs },
            { title: 'customer_portal', exact: true, path: CUSTOMERS_PORTAL, component: CustomerPortal },
            // Import pages
            {
                title: 'customer_import',
                exact: true,
                path: CUSTOMERS_IMPORT,
                component: CustomerImport,
                isImportPage: true
            },
            {
                title: 'customer_imported',
                exact: true,
                path: CUSTOMERS_IMPORTED,
                component: CustomerImported,
                isImportPage: true
            }
        ]
    },
    {
        path: SETTINGS_MAIN_PATH,
        component: SettingLayout,
        routes: [
            {
                title: 'company',
                exact: true,
                path: SETTINGS_COMPANY,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsCompany
            },
            {
                title: 'line_items',
                exact: true,
                path: SETTINGS_LINE_ITEMS,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsLineItems,
                routes: [
                    {
                        title: 'setting_global_override',
                        exact: true,
                        path: SETTINGS_LINE_ITEMS,
                        permissionKey: ACCESS_SETTINGS_TAB,
                        boxTip: LIST_TOOLTIP.ITEMS_OVERRIDE,
                        component: ItemOverride
                    }
                ]
            },
            {
                title: 'setting_plans',
                exact: true,
                path: SETTINGS_PLANS,
                component: SettingsPlans
            },
            {
                title: 'setting_invoices',
                exact: true,
                path: SETTINGS_INVOICES,
                component: SettingsInvoices
            },
            {
                title: 'invoices',
                exact: true,
                path: INVOICE_DETAIL,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsInvoiceDetail
            },
            {
                title: 'payment_methods',
                exact: true,
                path: SETTINGS_PAYMENT_METHODS,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsPaymentMethods
            },
            {
                title: 'tiles',
                exact: true,
                path: SETTINGS_TILES,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsTiles
            },
            {
                title: 'taxes',
                exact: true,
                path: SETTINGS_TAXES,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsTaxes,
                routes: [
                    {
                        title: 'setting_global_override',
                        exact: true,
                        path: SETTINGS_TAXES,
                        permissionKey: ACCESS_SETTINGS_TAB,
                        boxTip: LIST_TOOLTIP.TAXES_OVERRIDE,
                        component: TaxOverride
                    }
                ]
            },
            {
                title: 'tags',
                exact: true,
                path: SETTINGS_TAGS,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsTags
            },
            {
                title: 'users',
                exact: true,
                path: SETTINGS_USERS,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsUsers
            },
            {
                title: 'schedules',
                exact: true,
                path: SETTINGS_SCHEDULES,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsSchedules
            },
            {
                title: 'estimate_template',
                exact: true,
                path: SETTINGS_ESTIMATE,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsEstimate
            },
            {
                title: 'sources',
                exact: true,
                path: SETTINGS_SOURCES,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsSources
            },
            {
                title: 'invoice_template',
                exact: true,
                path: SETTINGS_INVOICE,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsInvoice
            },
            {
                title: 'service_templates',
                exact: true,
                path: SETTINGS_SERVICE,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsService
            },
            {
                title: 'add_service',
                exact: true,
                path: SETTINGS_SERVICE_ADD,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingAddService
            },
            {
                title: 'edit_service',
                exact: true,
                path: SETTINGS_SERVICE_EDIT,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingEditService
            },
            {
                title: 'customs',
                exact: true,
                path: SETTINGS_CUSTOMS,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsCustoms
            },
            {
                title: 'broadcast_template',
                exact: true,
                path: SETTINGS_BROADCAST,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsBroadcast
            },
            {
                title: 'email_inbox',
                exact: true,
                path: SETTINGS_EMAIL_INBOX,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsEmailInbox
            },
            {
                title: 'email_inbox',
                exact: true,
                path: SETTINGS_EMAIL_INBOX_DETAIL,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsEmailInboxDetails
            },
            {
                title: 'setting_system_email_sms',
                exact: true,
                path: SETTINGS_SYSTEMS,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsSystems
            },
            {
                title: 'setting_account',
                exact: true,
                path: SETTINGS_ACCOUNT,
                component: SettingsAccount
            },
            {
                title: 'setting_paperwork',
                exact: true,
                path: SETTINGS_PAPERWORK,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsPaperwork
            },
            {
                title: 'setting_templates_manager',
                exact: true,
                path: [
                    SETTINGS_TEMPLATES_MANAGER,
                    SETTINGS_TEMPLATES_TODO,
                    SETTINGS_TEMPLATES_NOTES,
                    SETTINGS_TEMPLATES_SMS
                ],
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsTemplatesManager,
                routes: [
                    {
                        title: 'setting_templates_manager',
                        exact: true,
                        path: SETTINGS_TEMPLATES_TODO,
                        permissionKey: ACCESS_SETTINGS_TAB,
                        component: TodoListManager
                    },
                    {
                        title: 'setting_templates_manager',
                        exact: true,
                        path: SETTINGS_TEMPLATES_NOTES,
                        permissionKey: ACCESS_SETTINGS_TAB,
                        component: NotesTemplate
                    },
                    {
                        title: 'setting_templates_manager',
                        exact: true,
                        path: SETTINGS_TEMPLATES_SMS,
                        permissionKey: ACCESS_SETTINGS_TAB,
                        component: SMSTemplates
                    },
                    {
                        title: 'setting_templates_manager',
                        exact: true,
                        path: SETTINGS_TEMPLATES_ESTIMATE,
                        permissionKey: ACCESS_SETTINGS_TAB,
                        component: EstimatesTemplate
                    }
                ]
            },
            {
                title: 'service_plan',
                exact: true,
                path: SETTINGS_SERVICE_PLAN,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingsServicePlan
            },
            {
                title: 'add_service_plan',
                exact: true,
                path: SETTINGS_SERVICE_PLAN_ADD,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingAddServicePlan
            },
            {
                title: 'edit_service_plan',
                exact: true,
                path: SETTINGS_SERVICE_PLAN_EDIT,
                permissionKey: ACCESS_SETTINGS_TAB,
                component: SettingAddServicePlan
            }
        ]
    },

    {
        path: [
            REPORT_MAIN_PATH,
            REPORT_LEADS,
            REPORT_LEADS_INBOUND,
            REPORT_LEADS_BOOKING,
            REVIEWS,
            REVIEWS_LIKELY,
            REVIEWS_SERVICE
        ],
        permissionKey: ACCESS_REPORT_TAB,
        component: ReportLayout,
        routes: [
            {
                title: 'invoices',
                exact: true,
                path: REPORT_INVOICE,
                component: ReportInvoice
            },
            {
                title: 'estimates',
                exact: true,
                path: REPORT_ESTIMATES,
                component: ReportEstimate
            },
            {
                title: 'payments',
                exact: true,
                path: REPORT_PAYMENT,
                component: ReportPayment
            },
            {
                title: 'documents',
                exact: true,
                path: REPORT_DOCUMENTS,
                component: ReportDocument
            },
            {
                title: 'credits',
                exact: true,
                path: REPORT_CREDIT,
                component: ReportCredit
            },
            {
                title: 'recurring_invoices',
                exact: true,
                path: REPORT_RECURRING_INVOICES,
                component: ReportRecurringInvoice
            },
            {
                title: 'stripe_subscriptions',
                exact: true,
                path: REPORT_SUBSCRIPTIONS,
                component: ReportSubscription
            },
            {
                title: 'square_subscriptions',
                exact: true,
                path: REPORT_SQUARE_SUBSCRIPTIONS,
                component: () => ReportSubscription({ type: PAYMENT_CARD_CUSTOMER.SQUARE })
            },
            {
                title: 'service_lookup',
                exact: true,
                path: REPORT_SERVICES_LOOKUP,
                component: ReportServiceLookup
            },
            {
                title: 'new_customers',
                exact: true,
                path: REPORT_NEW_CUSTOMERS,
                component: ReportNewCustomers
            },
            {
                title: 'location_sw_oactivejobs',
                exact: true,
                path: REPORT_LOCATION_WO_ACTIVE_JOBS,
                component: ReportLocationsWoActiveJobs
            },
            {
                title: 'customer_sw_oactivejobs',
                exact: true,
                path: REPORT_CUSTOMERS_WO_ACTIVE_JOBS,
                component: ReportCustomersWoActiveJobs
            },
            {
                title: 'accounts_aging',
                exact: true,
                path: REPORT_ACCOUNTS_AGING,
                component: ReportAccountsAging
            },
            {
                title: 'payments_collected',
                exact: true,
                path: REPORT_PAYMENTS_COLLECTED,
                component: ReportPaymentCollected
            },
            {
                title: 'total_sales',
                exact: true,
                path: REPORT_TOTAL_SALES,
                component: ReportTotalSales
            },
            {
                title: 'sales_forecast',
                exact: true,
                path: REPORT_SALES_FORECAST,
                component: ReportSalesForecast
            },
            {
                title: 'sales_tax_summary',
                exact: true,
                path: REPORT_SALES_TAX_SUMMARY,
                component: ReportSalesTaxSummary
            },
            {
                title: 'revenue_by_service',
                exact: true,
                path: REPORT_REVENUE_SERVICE,
                component: RevenueByService
            },
            {
                title: 'revenue_by_client',
                exact: true,
                path: REPORT_REVENUE_CLIENT,
                component: RevenueByClient
            },
            {
                title: 'revenue_by_item',
                exact: true,
                path: REPORT_REVENUE_ITEM,
                component: RevenueByItem
            },
            {
                title: 'revenue_by_staff',
                exact: true,
                path: REPORT_REVENUE_STAFF,
                component: RevenueByStaff
            },
            {
                title: 'revenue_by_source',
                exact: true,
                path: REPORT_REVENUE_SOURCE,
                component: RevenueBySource
            },
            {
                title: 'revenue_by_tags',
                exact: true,
                path: REPORT_REVENUE_TAGS,
                component: RevenueByTags
            },
            {
                title: 'material_use',
                exact: true,
                path: REPORT_MATERIAL_USE,
                component: MaterialUse
            },
            {
                title: 'email_history',
                exact: true,
                path: REPORT_EMAIL_LOG,
                component: EmailHistory
            },
            {
                title: 'leads',
                path: [REPORT_LEADS, REPORT_LEADS_INBOUND, REPORT_LEADS_BOOKING],
                component: Lead,
                exact: true
            },
            { title: 'reviews', path: [REVIEWS, REVIEWS_LIKELY, REVIEWS_SERVICE], component: Review, exact: true },
            { title: 'tracker', path: REPORT_TRACKER, component: ReportTracker, exact: true },
            { title: 'new_jobs', path: REPORT_NEW_JOB, component: ReportNewJob, exact: true },
            { title: 'device_tracking', path: REPORT_DEVICE_TRACKING, component: ReportDeviceTracking, exact: true },
            { title: 'sentricon_devices', path: REPORT_SENTRICON, component: ReportSentricon, exact: true }
        ]
    },

    {
        title: 'addons',
        path: ADDONS,
        component: AddonsLayout,
        permissionKey: ACCESS_ADDONS,
        excludePermissionRoute: [ADDONS_VOIP_MANAGE_PERSONAL, ADDONS_VOIP_MANAGE_GROUP, ADDONS_MULTIPLE_BRANCH_MANAGE],
        routes: [
            {
                title: 'addons_documents',
                exact: true,
                path: ADDONS_DOCUMENTS,
                component: AddonsDocument,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_documents_library',
                exact: true,
                path: ADDONS_DOCUMENTS_LIBRARY,
                component: LibraryDocuments,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_documents_available',
                exact: true,
                path: ADDONS_DOCUMENTS_AVAILABLE,
                component: AvailableDocuments,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_manage_documents_library',
                exact: true,
                path: ADDONS_DOCUMENTS_MANAGE,
                component: ManageDocuments,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_stripe',
                exact: true,
                path: ADDONS_STRIPE,
                component: AddonsStripe,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_stripe_subscription',
                exact: true,
                path: ADDONS_STRIPE_SUBSCRIPTION,
                component: StripeSubscription,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_square',
                exact: true,
                path: ADDONS_SQUARE,
                component: AddonsSquare,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_square_subscription',
                exact: true,
                path: ADDONS_SQUARE_SUBSCRIPTION,
                component: SquareSubscription,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_sms',
                exact: true,
                path: ADDONS_SMS,
                component: AddonsSms,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_review_engine',
                exact: true,
                path: ADDONS_REVIEW_ENGINE,
                component: AddonsReviewEngine,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_zapier',
                exact: true,
                path: ADDONS_ZAPIER,
                component: AddonsZapier,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_trigger',
                exact: true,
                path: ADDONS_TRIGGER,
                component: AddonsTrigger,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_email',
                exact: true,
                path: ADDONS_INBOX_EMAIL,
                component: AddonsInboxEmail,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_gps_tracking',
                exact: true,
                path: ADDONS_GPS_TRACKING,
                component: AddonsGpsTracking,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_device_tracking',
                exact: true,
                path: ADDONS_DEVICE_TRACKING,
                component: AddonsDevice,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_material_usage',
                exact: true,
                path: ADDONS_MATERIAL_USAGE,
                component: AddonsMaterialUsage,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_multi_tracking',
                exact: true,
                path: ADDONS_MULTI_UNIT,
                component: AddonsMultiUnit,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_multi_tracking_manager',
                exact: true,
                path: ADDONS_MULTI_UNIT_MANAGER,
                component: MultiTrackingManager,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_quickbooks_sync',
                exact: true,
                path: ADDONS_QUICKBOOKS_SYNC,
                component: AddonQuickBooksSync,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_quickbooks_sync',
                exact: true,
                path: ADDONS_QUICKBOOKS_SYNC_LOG,
                component: AddonQuickbooksLog,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_routing',
                exact: true,
                path: ADDONS_ROUTING,
                component: AddonsRouting,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_booking',
                exact: true,
                path: ADDONS_BOOKING,
                component: AddonsBooking,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_booking_settings',
                exact: true,
                path: ADDONS_BOOKING_SETTINGS,
                component: AddonsBookingSettings,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_portal',
                exact: true,
                path: ADDONS_PORTAL,
                component: AddonsPortal,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_commission_tracking',
                exact: true,
                path: ADDONS_COMMISSION_TRACKING,
                component: AddonsCommissionTracking,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_time_window',
                exact: true,
                path: ADDONS_TIME_WINDOW,
                component: AddonsTimeWindow,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_api',
                exact: true,
                path: ADDONS_API_KEYS,
                component: AddonsAPIKeys,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_VOIP',
                exact: true,
                path: ADDONS_VOIP,
                component: AddonsVOIP,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_VOIP_manage',
                exact: true,
                path: ADDONS_VOIP_MANAGE_PERSONAL,
                component: VOIPAddonsManage
            },
            {
                title: 'addons_VOIP_manage',
                exact: true,
                path: ADDONS_VOIP_MANAGE_GROUP,
                component: VOIPAddonsManage
            },
            {
                title: 'addons_VOIP_plan',
                exact: true,
                path: ADDONS_VOIP_PLAN,
                component: VOIPAddonsPlan,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_VOIP_active',
                exact: true,
                path: ADDONS_VOIP_ACTIVE_USERS,
                component: ActiveVOIPUsers,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_opportunity',
                exact: true,
                path: ADDONS_OPPORTUNITY,
                component: AddonsOpportunity,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_opportunity_manager',
                exact: true,
                path: ADDONS_OPPORTUNITY_MANAGE,
                component: AddonsOpportunityManage,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_opportunity_permissions',
                exact: true,
                path: ADDONS_OPPORTUNITY_PERMISSIONS,
                component: AddonsOpportunityPermissions,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_blocked_numbers',
                exact: true,
                path: ADDONS_BLOCKED_NUMBERS,
                component: BlockNumbers,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_multiple_branch',
                exact: true,
                path: ADDONS_MULTIPLE_BRANCH,
                component: AddonsMultipleBranch
            },
            {
                title: 'addons_multiple_branch',
                exact: true,
                path: ADDONS_MULTIPLE_BRANCH_PLAN,
                component: MultipleBranchPlan
            },
            {
                title: 'addons_multiple_branch',
                exact: true,
                path: ADDONS_MULTIPLE_BRANCH_MANAGE,
                component: MultipleBranchManage
            },
            {
                title: 'addons_VOIP_hold',
                exact: true,
                path: ADDONS_VOIP_HOLD,
                component: VOIPAddonsHold,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_smartview',
                exact: true,
                path: ADDONS_SMARTVIEW,
                component: AddonSmartView,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_smartview_permissions',
                exact: true,
                path: ADDONS_SMARTVIEW_PERMISSION,
                component: SmartViewPermission,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_work_log',
                exact: true,
                path: ADDONS_WORK_LOG,
                component: AddonsTracker
            },
            {
                title: 'addons_work_log_manage',
                exact: true,
                path: ADDONS_WORK_LOG_MANAGE,
                component: WorkLogManage
            },

            // Custom fields for addons
            {
                title: 'addons_custom_fields',
                exact: true,
                path: ADDONS_CUSTOM_FIELDS,
                component: AddonsCustomFields
            },
            {
                title: 'addons_custom_fields_manage',
                exact: true,
                path: ADDONS_CUSTOM_FIELDS_MANAGE,
                component: CustomFieldsManage
            },
            {
                title: 'addons_custom_fields_permissions',
                exact: true,
                path: ADDONS_CUSTOM_FIELDS_PERMISSIONS,
                component: CustomFieldsPermissions
            },
            {
                title: 'custom_job_status',
                exact: true,
                path: ADDONS_MAIN_JOB_STATUS,
                component: AddonsJobStatus
            },
            {
                title: 'custom_job_status_permissions',
                exact: true,
                path: ADDONS_JOB_STATUS_PERMISSION,
                component: (props) => (
                    <CustomFieldsPermissions {...props} addonsPermissionType={PERMISSIONS_JOB_STATUS} />
                )
            },
            {
                title: 'custom_job_status_manage',
                exact: true,
                path: ADDONS_JOB_STATUS_MANAGE,
                component: JobStatusManage
            },
            {
                title: 'addons_estimate',
                exact: true,
                path: ADDONS_ESTIMATE,
                component: AddonsEstimate
            },
            {
                title: 'addons_voicemail_drop',
                exact: true,
                path: ADDONS_VOIP_VOICEMAIL_DROP,
                component: VoicemailDrop
            },
            {
                title: 'best_available_time',
                exact: true,
                path: BEST_AVAILABLE_TIME_MANAGE,
                component: AddonsBestAvailableTime
            },
            { title: 'job_magnet_manage', exact: true, path: JOB_MAGNET_MANAGE, component: JobMagnetManage },
            { title: 'addons_chatbot', exact: true, path: ADDONS_AI, component: AddonsChatBotAI },
            {
                path: ADDONS_CHAT_BOT_MENU_OPTIONS.map((item) => item.link),
                component: ChatbotLayout,
                routes: [
                    {
                        title: 'addons_chatbot_content',
                        exact: true,
                        path: ADDONS_CHAT_BOT_CONTENT,
                        component: ChatbotContent
                    },
                    {
                        title: 'addons_chatbot_settings',
                        exact: true,
                        path: ADDONS_CHAT_BOT_SETTINGS,
                        component: ChatbotSettings
                    },
                    {
                        title: 'addons_chatbot_report',
                        exact: true,
                        path: ADDONS_CHAT_BOT_REPORT,
                        component: ChatbotReport
                    },
                    {
                        title: 'addons_chatbot_train_ai',
                        exact: true,
                        path: ADDONS_CHAT_BOT_TRAIN_AI,
                        component: ChatbotTrainAI
                    },
                    {
                        title: 'addons_chatbot_custom_answer',
                        exact: true,
                        path: ADDONS_CHAT_BOT_CUSTOM_ANSWER,
                        component: ChatbotCustomerAnswer
                    }
                ]
            },
            {
                title: 'addons_service_plan',
                exact: true,
                path: ADDONS_SERVICE_PLAN,
                component: AddonServicePlan,
                permissionKey: ACCESS_SETTINGS_TAB
            },
            {
                title: 'addons_portal_widget',
                exact: true,
                path: ADDONS_PORTAL_WIDGET,
                component: PortalWidgetSetting
            },
            {
                title: 'addons_device_tracking_sentricon',
                exact: true,
                path: ADDONS_SENTRICON_MANAGE,
                component: SentriconManage
            }
        ]
    },
    { title: 'commissions', path: COMMISSIONS, component: CommissionLayout, exact: true },
    {
        title: 'dashboard',
        path: DASHBOARD,
        component: Dashboard,
        exact: true
    },
    { title: 'tracker', path: TRACKER, component: Tracker, exact: true },
    { title: 'referral', path: REFERRAL, component: Referral, exact: true },
    { title: 'onboarding', path: ONBOARDING, component: OnBoarding, exact: true },
    {
        title: 'profile_authentication',
        path: PROFILE_AUTHENTICATION,
        component: ProfileAuthLayout,
        exact: false
    }
];

export const PUBLIC_PATHS_CHECK = [
    '/confirmation',
    'admin/auth',
    '/portal',
    'addons/portal',
    '/payment/process/token',
    '/signup/activate',
    '/password/reset',
    '/signup/social'
];
