import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';
import {
    ADDONS_CHAT_BOT_CONTENT,
    ADDONS_CHAT_BOT_CUSTOM_ANSWER,
    ADDONS_CHAT_BOT_REPORT,
    ADDONS_CHAT_BOT_SETTINGS,
    ADDONS_CHAT_BOT_TRAIN_AI
} from 'app/config/routes';
import { DEFAULT_ALL, DIVIDER, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import i18n from 'assets/i18n';
import IconCalendar from 'assets/icon/IconCalendar';
import IconCheck from 'assets/icon/IconCheck';
import IconClose from 'assets/icon/IconClose';
import IconEmail from 'assets/icon/IconEmail';
import IconHome from 'assets/icon/IconHome';
import IconPortfolio from 'assets/icon/IconPortfolio';
import IconSentSms from 'assets/icon/IconSentSms';
import IconSMS from 'assets/icon/IconSMS';
import IconUser from 'assets/icon/IconUser';

export const ADDONS_CHAT_BOT_MENU_OPTIONS = [
    { id: 1, value: 'content', link: ADDONS_CHAT_BOT_CONTENT },
    { id: 2, value: 'custom_answers', link: ADDONS_CHAT_BOT_CUSTOM_ANSWER },
    { id: 3, value: 'train_ai', link: ADDONS_CHAT_BOT_TRAIN_AI, isHide: true },
    { id: 4, value: 'report', link: ADDONS_CHAT_BOT_REPORT },
    { id: 5, value: 'settings', link: ADDONS_CHAT_BOT_SETTINGS }
];

export const STATUS_OPTIONS = [
    { id: 1, name: 'active', value: 1, classNameStatus: 'active' },
    { id: 0, name: 'inactive', value: 0, classNameStatus: 'inactive' }
];
export const getReplyBehaviorOptions = () => [
    { id: DEFAULT_ALL, title: i18n.t('addons:do_not_reply'), hideWhenActiveHours: true },
    { id: DIVIDER, hideWhenActiveHours: true },
    { id: 0, title: i18n.t('addons:reply_immediately') },
    { id: DIVIDER },
    { id: 1, title: i18n.t('addons:reply_after_minute', { minute: 1 }) },
    { id: 3, title: i18n.t('addons:reply_after_minutes', { minute: 3 }) },
    { id: 5, title: i18n.t('addons:reply_after_minutes', { minute: 5 }) }
];
export const MODE_CHART_TRAIN_AI = {
    ALL: 'all',
    THIS_MONTH: 'this-month',
    THIS_YEAR: 'this-year',
    LAST_YEAR: 'last-year'
};
export const TAB_CHART_TRAIN_AI = [
    { value: MODE_CHART_TRAIN_AI.ALL, label: 'all_time' },
    { value: MODE_CHART_TRAIN_AI.THIS_YEAR, label: 'this_year' },
    { value: MODE_CHART_TRAIN_AI.LAST_YEAR, label: 'last_year' },
    { value: MODE_CHART_TRAIN_AI.THIS_MONTH, label: 'this_month' }
];

export const STATUS_TRAIN_OPTIONS = [
    {
        id: 1,
        name: 'trained',
        value: 1,
        classNameStatus: 'has-icon active svg-white-stroke',
        icon: <IconCheck />
    },
    {
        id: 0,
        name: 'untrained',
        value: 0,
        classNameStatus: 'has-icon error svg-white-stroke',
        icon: <IconClose isSmall />
    }
];

export const DELETE = 'DELETE';
export const ARCHIVE = 'ARCHIVE';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const TYPE_DATA_CHATBOT = { UPDATE: 'UPDATE', [DELETE]: DELETE };
export const BATCH_ACTION = { CONTENT: 'content', CUSTOM_ANSWER: 'customanswers' };
export const DATA_WARNING_TYPE = { CONTENT: 'CONTENT', CUSTOM_ANSWER: 'CUSTOM_ANSWER' };
export const ACTION_BUTTON_TYPE = { [STATUS_OPTIONS[1].id]: INACTIVE, [STATUS_OPTIONS[0].id]: ACTIVE };
export const MARK_AS_OPTIONS = [
    { id: 1, name: 'mark_active', value: 1 },
    { id: 0, name: 'mark_inactive', value: 0 }
];
export const ACTION_BUTTON = {
    [MARK_AS_OPTIONS[0].id]: { label: ACTIVE.toLowerCase(), type: 1, status: MARK_AS_OPTIONS[0].id },
    [MARK_AS_OPTIONS[1].id]: { label: INACTIVE.toLowerCase(), type: 0, status: MARK_AS_OPTIONS[1].id },
    [TYPE_BUTTON_ACTIONS.DELETE]: { label: DELETE.toLowerCase(), type: 2 }
};
export const REPORT_STATUS_OPTIONS = [
    { id: 1, name: 'ai_resolved', value: 1, classNameStatus: 'resolved' },
    { id: 0, name: 'not_resolved', value: 0, classNameStatus: 'not-resolved' },
    { id: 2, name: 'agent_resolved', value: 2, classNameStatus: 'agent-resolved' }
];
export const CHATBOT_SETTING_INFO = {
    NAME_AI: 'name_ai',
    OFFICE_HOURS_GREETING: 'office_hours_greeting',
    AFTER_HOURS_GREETING: 'after_hours_greeting',
    DEFAULT_ACTIVE_DAY: { SU: {}, MO: {}, TU: {}, WE: {}, TH: {}, FR: {}, SA: {} },
    DAY_CODE_LABELS: { SU: 'sun', MO: 'mon', TU: 'tue', WE: 'wed', TH: 'thu', FR: 'fri', SA: 'sat' }
};
export const LIST_RECHARGE_AMOUNT = [150, 300, 600, 1000];
export const LIST_RECHARGE_THRESHOLD = [25, 50, 100];
export const LIST_CLASS_DIVIDE = ['sm', 'md', 'lg', 'xl'];
export const NAME_CHATBOT = 'AI Agents';
export const FORMAT_MONTH_CHATBOT_PLAN = 'MMM YYYY';
export const TOOLBAR_CONFIG_RESTORE = {
    right: [{ group: 'restore', children: [{ type: TYPE_FUNCTION_RICHTEXT.RESTORE }] }]
};
export const ACTIVE_HOURS = 'active_hours';
export const INACTIVE_HOURS = 'inactive_hours';
export const AI_AGENTS_LEARN_MORE_URL =
    'https://intercom.help/gorilladesk/en/articles/10006348-ai-agents-sms-request-early-access';
export const SETTING_PORTAL_AI = {
    LIST_COLOR_SETTING_AI_PORTAL: [
        { id: 1, name: 'blue', color: '#2860CA' },
        { id: 2, name: 'purple', color: '#7632F4' },
        { id: 3, name: 'magenta', color: '#DA1CE7' },
        { id: 4, name: 'pink', color: '#E71C8F' },
        { id: 5, name: 'red', color: '#D72B2E' },
        { id: 6, name: 'orange', color: '#FB6920' },
        { id: 7, name: 'olive', color: '#567C11' },
        { id: 8, name: 'green', color: '#067133' },
        { id: 9, name: 'aquamarine', color: '#03B0A2' },
        { id: 10, name: 'grey', color: '#515151' }
    ],
    DEFAULT_COLOR: { id: 8, name: 'Green', color: '#067133' }
};
export const CHATBOT_TYPE = { SMS_AI: 0, PORTAL_AI: 1, VOIP_AI: 2 };
export const TYPE = 'type';
export const AI_TYPE = 'ai_type';
export const MESSAGE_ACTION_ROLE = { ASSISTANT: 'assistant', CUSTOMER: 'customer' };
export const TAB_BUTTON_SHAPE = [
    { value: 'rounded_square', label: 'rounded_square' },
    { value: 'circle', label: 'circle' }
];
export const TAB_THEME = [
    { value: 'light', label: 'light' },
    { value: 'dark', label: 'dark' }
];
export const BACKGROUND_VARIANT_OPTION = {
    SOLID_COLOR: { value: 'solid_color', label: 'solid_color' },
    PATTERN: { value: 'pattern', label: 'pattern' }
};
export const BUTTON_SHAPE = 'button_shape';
export const PORTAL_COLOR = 'color';
export const THEME = 'theme';
export const BACKGROUND_VARIANT = 'background_variant';
export const ICON_PORTAL_AI_SETTING = {
    ICON: 'icon',
    LIST_ICON: [
        { id: 1, name: 'message_bubble', icon: <IconSMS isBlack /> },
        { id: 2, name: 'house', icon: <IconHome isBlack /> },
        { id: 3, name: 'portfolio', icon: <IconPortfolio /> },
        { id: 4, name: 'chat', icon: <IconSentSms isBlack /> },
        { id: 5, name: 'user', icon: <IconUser isBlack /> },
        { id: 6, name: 'email', icon: <IconEmail isBlack /> },
        { id: 7, name: 'calendar', icon: <IconCalendar isBlack /> }
    ],
    DEFAULT_ICON: { id: 1, name: 'message_bubble', icon: <IconSMS isBlack /> }
};
