import { getListJobStatus } from 'common/utils/JobStatusUtils';
import { COLUMNS_BUILT_IN } from '../../constants/columns';
import { OBJECT_TYPES, OPERATOR_IDS } from '../../constants/types';
import { DEFAULT_COLUMN_NAME } from '../table/utils';
import { getOperatorMomentRange } from './dateOperator';
import { getOperationDurationRange } from './durationOperator';
import { getOperatorNumberRange } from './numberRangeOperator';
import { getOperationText } from './textOperator';
import { JOB_CUSTOM } from 'app/const/Job';

export const getFilterValue = (type, condition, negate = false) => {
    switch (type) {
        case 'exists':
            return { value: undefined, operatorId: negate ? OPERATOR_IDS.NOT_PRESENT : OPERATOR_IDS.PRESENT };
        case 'set':
            return {
                value: { set: { values: condition.value || condition.values } },
                operatorId: negate ? OPERATOR_IDS.NOT_IN : OPERATOR_IDS.IN
            };
        case 'moment_range':
            return getOperatorMomentRange(condition, negate);
        case 'number_range':
            return getOperatorNumberRange(condition, negate);
        case 'duration_range':
            return getOperationDurationRange(condition, negate);
        case 'text':
            return getOperationText(condition, negate);
        default:
            return { value: condition.value, operatorId: OPERATOR_IDS.IN };
    }
};

export const convertToFiltersWithValues = (data, { allows = [] } = {}) => {
    if (!data?.queries) return [];
    const processQueries = (queries) => {
        return queries.flatMap((query) => {
            if (query.type === 'and' || query.type === 'or') {
                return { operatorId: query.type, filters: processQueries(query.queries) };
            } else if (query.type === 'field_condition') {
                const queryField = query.field;
                const dataFilterValue = getFilterValue(query.condition.type, query.condition, query.negate);

                if (queryField.type === OBJECT_TYPES.CUSTOM_FIELD.toLowerCase()) {
                    if (!allows.includes(OBJECT_TYPES.CUSTOM_FIELD)) return [];
                    return {
                        objectType: OBJECT_TYPES.CUSTOM_FIELD,
                        fieldName: queryField.custom_field_id,
                        ...dataFilterValue
                    };
                }

                if (queryField.object_type === OBJECT_TYPES.JOB.toLowerCase()) {
                    const isTypeSet = query.condition.type === 'set';
                    const isAllowCustomJobStatus = allows.includes(OBJECT_TYPES.CUSTOM_JOB_STATUSES);
                    const isDisableCustomJobStatus =
                        !isAllowCustomJobStatus && isTypeSet && query.field.field_name === 'status';
                    // Custom jobs have id always greater than 100
                    if (isDisableCustomJobStatus && parseInt(query.condition.value?.[0]) >= 100) return [];
                    return {
                        objectType: queryField.object_type.toUpperCase(),
                        fieldName: queryField.field_name,
                        ...dataFilterValue
                    };
                }
                return {
                    objectType: queryField.object_type.toUpperCase(),
                    fieldName: queryField.field_name,
                    ...dataFilterValue
                };
            }
            return [];
        });
    };

    return processQueries(data.queries);
};

export const convertToSortWithValues = (data) => {
    try {
        const result = [];
        const jobStatuses = getListJobStatus({ filter: [JOB_CUSTOM], isInclude: true, isIncludeDeleted: true });

        data.forEach(({ field, direction }) => {
            const { object_type, field_name, type, custom_status_job, custom_field_id } = field;
            const objectType = object_type?.toUpperCase();

            if (objectType === OBJECT_TYPES.JOB && jobStatuses.some((status) => status.id === custom_status_job)) {
                result.push({ objectType: OBJECT_TYPES.JOB, fieldId: field.custom_status_job, direction });
            } else if (type === OBJECT_TYPES.CUSTOM_FIELD.toLowerCase()) {
                result.push({ objectType: OBJECT_TYPES.CUSTOM_FIELD, fieldId: custom_field_id, direction });
            } else if (COLUMNS_BUILT_IN[field_name]) {
                result.push({ objectType, fieldId: field_name, direction });
            } else if (field_name === 'phone_number') {
                result.push({ objectType, fieldId: field_name, direction });
            }
        });
        return result;
    } catch (error) {
        console.error('Error convertToSortWithValues', error);
        return [];
    }
};

export const convertToColumnsWithValues = (data, { allows = [] } = {}) => {
    if (!data) return DEFAULT_COLUMN_NAME;
    const columns = [];
    const includeCustomFields = allows.includes(OBJECT_TYPES.CUSTOM_FIELD);
    const includeCustomJobStatus = allows.includes(OBJECT_TYPES.CUSTOM_JOB_STATUSES);
    data.forEach((item) => {
        const colName = item.field_id;
        if (item.type_id === OBJECT_TYPES.CUSTOM_FIELD && includeCustomFields) columns.push(item);
        if (item.type_id === OBJECT_TYPES.JOB && !COLUMNS_BUILT_IN[colName] && includeCustomJobStatus)
            columns.push(item);
        if (COLUMNS_BUILT_IN[colName]) columns.push(item);
    });
    return columns;
};

export const getNegative = (operatorId) => {
    const listNegatives = [
        OPERATOR_IDS.NOT,
        OPERATOR_IDS.NOT_IN,
        OPERATOR_IDS.NOT_BETWEEN,
        OPERATOR_IDS.NOT_WITHIN,
        OPERATOR_IDS.NOT_EXACTLY,
        OPERATOR_IDS.NOT_PRESENT,
        OPERATOR_IDS.NOT_CONTAINS_WORDS_STARTING_WITH,
        OPERATOR_IDS.NOT_EXACT_VALUE
    ];
    return listNegatives.includes(operatorId);
};
