import classNames from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { SETTINGS_PLANS } from 'app/config/routes';
import { SMS_ADDONS_OVERRIDE_GLOBAL, STRIPE_ADDONS_OPTIONS } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import {
    ADDONS_OPTION_NOT_POWERUP,
    ADDONS_OPTION_NOT_SWITCH,
    ADDONS_OPTION_SHOW_PRO,
    ADDONS_SMS_AFTER_HOURS_KEY,
    ADDONS_SMS_GLOBAL,
    ADDONS_SQUARE_READER_OPTION,
    ADDONS_VOIP_AFTER_HOURS,
    KEY_ADVANCED_BALANCE_DUE,
    ADDONS_OPTION_DISABLE_ON_INACTIVE,
    ADDON_FEATURES_COMING_SOON,
    ADDONS_STRIPE_ACH_SURCHARGE
} from 'app/const/addons';
import IconStar from 'assets/icon/IconStar';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import { AddonItemAfterHours } from '../voip/components/AddonItemAfterHours';
import AddonsDescription from './AddonsDescription';
import AddonsOptionsFooter from './AddonsOptionsFooter';
import OptionItems from './OptionItems';
import IconGrowth from 'assets/icon/IconGrowth';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';

const AddonsOptions = ({ data = [], ...props }) => {
    return data.map((item) => <AddonsOptionsItem key={item.keyword} {...item} {...props} />);
};

export const AddonsOptionsItem = ({
    name,
    keyword,
    description,
    usage = null,
    value,
    upgrade,
    urlUpdate = STRIPE_ADDONS_OPTIONS,
    handleManagerSuccess,
    status,
    count,
    onDisplayAlert = () => {},
    items = null,
    isPowerUp = false,
    isUpgradeGrowthIcon = false,
    shouldCheckManage = false,
    pathManage = '',
    onUpgrade,
    urlUpdateStatus = '',
    onConfirmChange,
    ...props
}) => {
    const { t } = useTranslation('addons');
    const dispatch = useDispatch();
    const [isEnabled, setIsEnabled] = useState(!!value);
    const refConfirm = useRef(null);
    const refButtonSave = useRef(null);

    const isNotSwitch = ADDONS_OPTION_NOT_SWITCH.includes(keyword);
    const isNotPowerUp = ADDONS_OPTION_NOT_POWERUP.includes(keyword) && !isPowerUp;
    const isDisplayPro = ADDONS_OPTION_SHOW_PRO.includes(keyword);
    const isDisplayComingSoon = ADDON_FEATURES_COMING_SOON.includes(keyword);

    useEffect(() => {
        !!value !== isEnabled && setIsEnabled(!!value);
    }, [value]);

    const _handleChange = (checked) => {
        /* This is a workaround to prevent and open confirm modal when user from turning on the SMS Global Override. */
        if (keyword === ADDONS_SMS_GLOBAL && checked) return refConfirm.current._open();
        if (typeof onConfirmChange === 'function') {
            onConfirmChange();
            return;
        }

        setIsEnabled(checked);
        const data = { value: checked ? 1 : 0, keyword };
        if (urlUpdateStatus) delete data['keyword'];
        clientQuery(urlUpdateStatus || urlUpdate, { data, method: 'PUT' }, _handleUpdateSuccess, checkAccessFail);
    };

    const _handleUpdateSuccess = ({ data }) => {
        const keyword = Object.keys(data)[0];
        keyword && dispatch(updateAddonStatus({ keyword, data: data[keyword] }));
    };

    const _handleCloseConfirm = () => refConfirm.current._close();
    const _handleConfirm = () => {
        const _handleOverrideError = ({ message }) => {
            refConfirm.current._setStatusAlert(message);
        };

        const _handleOverrideSuccess = () => {
            setIsEnabled(true);
            clientQuery(SMS_ADDONS_OVERRIDE_GLOBAL, { data: {}, method: 'PUT' });
            clientQuery(urlUpdate, { data: { value: 1, keyword: 'sms_global' }, method: 'PUT' });
            dispatch(updateAddonStatus({ keyword: 'smsplivo', data: { sms_global: 1 } }));

            _handleCloseConfirm();
        };

        const _handleOverrideFinally = () => {
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            SMS_ADDONS_OVERRIDE_GLOBAL,
            { data: {}, method: 'PUT' },
            _handleOverrideSuccess,
            _handleOverrideError,
            _handleOverrideFinally
        );
    };
    const isDisabledChange = !!!status && ADDONS_OPTION_DISABLE_ON_INACTIVE.includes(keyword);

    const _renderSwitch = () => {
        if (keyword === ADDONS_SQUARE_READER_OPTION)
            return (
                <p className={classNames('switch__label', { '--active': !!status })}>
                    {!!status ? t('addons:enabled') : t('addons:disabled')}
                </p>
            );

        if (!!isNotSwitch) return null;

        return (
            <GdSwitchCheckbox
                id={keyword}
                wrapClassName="switch large"
                inputClassName="toggle toggle-round"
                textUnChecked={t('common:disabled')}
                textChecked={t('common:enabled')}
                defaultChecked={isEnabled}
                onChangeImmediately={_handleChange}
            />
        );
    };

    const _handleClickUpgrade = (e) => {
        if (onUpgrade) {
            e.preventDefault();
            onUpgrade();
        }
    };

    return (
        <>
            {keyword === KEY_ADVANCED_BALANCE_DUE && (
                <h5 className="title --sm mt-4 mb-1">{t('addons:recommended_to_be_disabled')}</h5>
            )}

            <div className={classNames('boxs boxs--power-up boxs-has-footer', { active: isEnabled })}>
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="title">{name}</div>
                        {!isNotPowerUp && (
                            <div className="dots powerups">
                                <IconUpgrade isFillOrange />
                                {t('power_up')}
                            </div>
                        )}
                        {isDisplayPro && (
                            <div className="dots powerups">
                                <div className="status-btn --has-icon --pro">
                                    <IconUpgrade isAddonUpgrade />
                                    {t('addons_pro')}
                                </div>
                            </div>
                        )}
                    </div>
                    {!!!upgrade ? (
                        <Fragment>
                            {isDisplayComingSoon ? (
                                <div className="status-btn --beta fs-12">{t('setting:coming_soon')}</div>
                            ) : (
                                <div className={classNames('switch large', { 'is-disable': isDisabledChange })}>
                                    {_renderSwitch()}
                                </div>
                            )}
                        </Fragment>
                    ) : isUpgradeGrowthIcon ? (
                        <Link
                            to={addBranchPath(SETTINGS_PLANS)}
                            className="v2-btn-main bg-green-default has-icon white"
                            onClick={_handleClickUpgrade}
                        >
                            <IconGrowth />
                            <span className="line">{t('addons:upgrade_to_growth')}</span>
                        </Link>
                    ) : (
                        <Link
                            to={addBranchPath(SETTINGS_PLANS)}
                            className="btn-upgrage svg-star-white"
                            onClick={_handleClickUpgrade}
                        >
                            <IconStar />
                            <span className="line">{t('addons:upgrade')}</span>
                        </Link>
                    )}
                </div>
                <div className="boxs__contents">
                    <AddonsDescription keyword={keyword} description={description} />

                    {keyword === ADDONS_SMS_AFTER_HOURS_KEY && usage ? (
                        <div className="mt-3 pt-3 border-top-border-color-grey js-box-content">
                            <div className="flex-betweenitems mb-1">
                                <p>{t('addons:after_hours_usage_this_month')}</p>
                                <p className="fw-500">{usage.this_month}</p>
                            </div>
                            <div className="flex-betweenitems">
                                <p>
                                    {t('addons:after_hours_usage_last_month')} ({usage.last_month_label})
                                </p>
                                <p className="fw-500">{usage.last_month}</p>
                            </div>
                        </div>
                    ) : null}
                    {keyword === ADDONS_VOIP_AFTER_HOURS && <AddonItemAfterHours usage={usage} />}
                </div>
                {!!items &&
                    Array.isArray(items) &&
                    items.map((item, index) => {
                        return (
                            <OptionItems
                                key={index.toString()}
                                {...item}
                                // TODO: Update from upgrade to item.upgrade for all addons
                                upgrade={item.keyword === ADDONS_STRIPE_ACH_SURCHARGE ? item.upgrade : upgrade}
                                urlUpdate={urlUpdate}
                                onUpdateSuccess={_handleUpdateSuccess}
                            />
                        );
                    })}
                <AddonsOptionsFooter
                    {...props}
                    keyword={keyword}
                    upgrade={upgrade}
                    shouldCheckManage={shouldCheckManage}
                    onDisplayAlert={onDisplayAlert}
                    isEnabled={isEnabled}
                    status={status}
                    pathManage={pathManage}
                    handleManagerSuccess={handleManagerSuccess}
                    items={items}
                    count={count}
                />
            </div>

            {keyword === ADDONS_SMS_GLOBAL && (
                <GDModalWarning
                    ref={refConfirm}
                    title={t('turn_on_global_override')}
                    description={t('description_override_sms_global')}
                    footer={
                        <div className="footer-modal btn-close justify-end">
                            <div className="v2-btn-main" onClick={_handleCloseConfirm}>
                                {t('addons:not_now')}
                            </div>
                            <ButtonSave
                                ref={refButtonSave}
                                title={t('addons:turn_on_sms_global_override')}
                                wrapClass="v2-btn-main ml-2"
                                onSave={_handleConfirm}
                            />
                        </div>
                    }
                />
            )}
        </>
    );
};

export default AddonsOptions;
