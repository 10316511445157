import classNames from 'classnames';
import React, { Fragment, useCallback, useEffect, useId, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { addBranchPath } from 'app/const/Branch';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import {
    ACTION_LIST_TEMPLATE,
    NOTES_TEMPLATE_OPTIONS,
    NOTES_TEMPLATE_TYPE,
    PAYMENT_TERMS_OPTION,
    TEMPLATE_KEYWORD
} from 'app/const/Notes';
import { reducer } from 'app/const/Reducer';
import { LIST_ACTIONS } from 'app/modules/jobdetail/const';
import { INVOICE_STATUS } from 'app/modules/jobdetail/const/Invoice';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { PAYMENT_OPEN_TERM } from 'app/modules/settings/templatesManager/components/notes/const';
import { getTitleTemplate } from 'app/modules/settings/utils';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconDone from 'assets/icon/IconDone';
import IconFille from 'assets/icon/IconFile';
import IconFolder from 'assets/icon/IconFolder';
import IconNote from 'assets/icon/IconNote';
import IconPlus from 'assets/icon/IconPlus';
import { actionToggleInsertJobNotes } from 'common/redux/actions/notesAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { isVisibleManageTemplate } from 'common/utils/PermissionUtils';
import { Manager, Popper, Reference } from 'react-popper';
import GDAttachments from '../attachments';
import Filter from './Filter';
import Loading from './Loading';
import ModalAddTerms from './ModalAddTerms';
import TemplateName from './TemplateName';
import GdConfirm from '../confirm';

export default function TemplateOptions({
    isShow = false,
    typeFilter = 6,
    onSelectTemplate = () => {},
    isInsertJobNote = false,
    isInsertInvoiceNote = false,
    isInsertWorkOrderNote = false,
    isShowSaveAs = false,
    isShowOnlyIcon = false,
    wrapperClassName = 'v2-dropdown list-note-templates',
    btnClassName = 'dropbtn v2-btn-default has-icon bg-white',
    contentClassName = 'v2-dropdown__menu content-search',
    itemClassName = 'items justify-space-between items-template',
    isShowFilter = true,
    typeTemplate = TEMPLATE_KEYWORD.NOTE,
    strategy = 'fixed',
    templateValue = {},
    isInvoice = false,
    invoiceStatus = '',
    onOpenSaveAs = () => {},
    onSetDefaultNote = () => {}
}) {
    const { t } = useTranslation(['jobDetail', 'header']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isFetched: false,
        isLoading: true,
        data: [],
        filter: [typeFilter],
        total: 0,
        keyword: '',
        isChanged: 0,
        showMore: false,
        cursor: ''
    });
    const refOptions = useRef(null);
    const refTemplateValue = useRef(null);
    const refAddTerms = useRef(null);
    const refConfirm = useRef(null);
    const idTemplateForm = useId();
    const { resetTemplates, toggleInsert } = useSelector(({ notesReducer }) => notesReducer);
    const permissions = useSelector(({ auth }) => auth.user.permissions.enabled || []);
    const { openTime } = toggleInsert || {};
    const { getListTemplate, pathTemplate, tagTemplate, additionalAccess } = ACTION_LIST_TEMPLATE[typeTemplate];
    const dispatch = useDispatch();
    const isFilterPaymentTerms = typeFilter === NOTES_TEMPLATE_TYPE.PAYMENT_TERMS;

    const {
        isVisible: finalIsVisible,
        filter: finalDataFilter,
        isFetched,
        keyword: finalKeyword,
        isChanged,
        isLoading,
        data: finalData,
        total: finalTotal,
        showMore,
        cursor
    } = state;

    /**
     * This action reset the templates after add new
     */
    useEffect(() => {
        if (resetTemplates && isFetched) {
            dispatchState((prev) => {
                return {
                    ...prev,
                    isLoading: true,
                    isFetched: false,
                    keyword: '',
                    filter: [typeFilter],
                    isChanged: 0,
                    data: [],
                    showMore: false,
                    cursor: ''
                };
            });
        }
    }, [resetTemplates]);

    useEffect(() => {
        if (finalIsVisible) {
            !isFetched && _getData();

            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    useEffect(() => {
        if (isChanged) {
            _getData({ isSearching: true });
        }
    }, [finalKeyword, finalDataFilter, isChanged]);

    useEffect(() => {
        if (!refTemplateValue.current) return;
        refTemplateValue.current.setItem(templateValue);
    }, [templateValue]);

    function _getData({ isLoading = false, isSearching = false } = {}) {
        if (isLoading) dispatchState((prev) => ({ ...prev, isLoading: true }));
        clientQuery(
            getListTemplate,
            {
                method: 'GET',
                data: {
                    type: finalDataFilter.toString(),
                    limit: 20,
                    keyword: finalKeyword,
                    total: 1,
                    cursor: isSearching ? '' : cursor
                }
            },
            _getDataSuccess,
            _getDataFailed
        );
    }

    function _getDataSuccess(response) {
        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: false,
                data: [...prev.data, ...response.data],
                total: response.total,
                isFetched: true,
                showMore: response.show_more,
                cursor: response.cursor
            };
        });
    }

    function _getDataFailed() {
        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: false,
                isFetched: true
            };
        });
    }

    function _handleApplyFilter(newFilter) {
        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: true,
                filter: newFilter,
                data: [],
                isChanged: Date.now()
            };
        });
    }

    function _handleChangeSearch(keyword) {
        dispatchState((prev) => {
            return {
                ...prev,
                keyword,
                isLoading: true,
                isChanged: Date.now(),
                data: []
            };
        });
    }

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(idTemplateForm);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(idTemplateForm);
        const addTermEl = document.getElementById('modal_add_terms');

        if (
            refOptions.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refOptions.current.contains(event.target) &&
            !addTermEl?.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        dispatchState({ isVisible: !finalIsVisible });
    }

    function _handleSelectItem(itemSelect) {
        if (
            isInvoice &&
            isFilterPaymentTerms &&
            [INVOICE_STATUS.PAID, INVOICE_STATUS.PARTIAL].includes(invoiceStatus)
        ) {
            refConfirm.current &&
                refConfirm.current.open(
                    null,
                    t('jobDetail:message_block_update_terms', {
                        name: t(`report:${invoiceStatus === INVOICE_STATUS.PAID ? 'paid' : 'partial'}`)
                    })
                );
            return;
        }
        if (isFilterPaymentTerms && refTemplateValue.current) {
            refTemplateValue.current.setItem(itemSelect);
        }
        onSelectTemplate(itemSelect);
        _closeSearchResult();
    }

    function _renderTemplates() {
        const finalTemplates = isFilterPaymentTerms
            ? [
                  {
                      ...PAYMENT_OPEN_TERM,
                      name: t(`common:${PAYMENT_OPEN_TERM.name}`),
                      type: NOTES_TEMPLATE_TYPE.PAYMENT_TERMS
                  },
                  ...finalData
              ]
            : finalData;
        if (!isLoading && !finalTemplates.length) {
            return (
                <li className="items items-template pointer-events-none">
                    <p className="word-break flex-1">
                        {finalKeyword
                            ? t('header:search_not_match')
                            : t('common:no_data_to_display', { title: t('common:templates') })}
                    </p>
                </li>
            );
        }

        return finalTemplates.map((item) => {
            return (
                <TemplateItem
                    key={item.id}
                    item={item}
                    tagTemplate={tagTemplate}
                    typeTemplate={typeTemplate}
                    onSelect={_handleSelectItem}
                    itemClassName={classNames(itemClassName, { active: item.id === templateValue.id })}
                    strategy={strategy}
                />
            );
        });
    }

    const handleOnScrollContent = (e) => {
        if (!isLoading && isScrollToEndBottom(e.target) && (finalTotal > finalData.length || showMore)) {
            _getData({ isLoading: true });
        }
    };

    const _insertJobNote = (e) => {
        dispatch(actionToggleInsertJobNotes({ openTime: Date.now(), callback: onSelectTemplate }));
        _handleOpen(e);
    };

    const _handleInsertNote = (e, type) => {
        onSelectTemplate({ type });
        _handleOpen(e);
    };

    const _handleAddNewTemplate = () => {
        refAddTerms.current && refAddTerms.current._open();
    };

    const _handleAddTemplate = (data = {}) => {
        dispatchState((prev) => ({ ...prev, data: [data, ...prev.data], total: prev.total + 1 }));
    };

    if (!isShow) {
        return false;
    }

    return (
        <div ref={refOptions} id={idTemplateForm} className={classNames(wrapperClassName, { active: finalIsVisible })}>
            <div className={classNames(btnClassName, { 'w-100': isFilterPaymentTerms })} onClick={_handleOpen}>
                <IconFille />
                {!isShowOnlyIcon ? (
                    <Fragment>
                        <TemplateName
                            ref={refTemplateValue}
                            isInsertJobNote={isInsertJobNote}
                            type={typeFilter}
                            {...templateValue}
                        />
                        <span className="arrow">
                            <IconArrowDown />
                        </span>
                    </Fragment>
                ) : null}
            </div>

            <div className={contentClassName}>
                <div className="container-column">
                    {isVisibleManageTemplate({ permissions, additionalAccess }) && (
                        <ul>
                            {isFilterPaymentTerms ? (
                                <div className="items has-icon btn-modal" onClick={_handleAddNewTemplate}>
                                    <IconPlus />
                                    {t('setting:add_new_template')}
                                </div>
                            ) : isInvoice ? (
                                <li className="items has-icon" onClick={onSetDefaultNote}>
                                    <IconDone />
                                    <p className="txt-ellipsis" title={t('jobDetail:set_default_for_all_invoices')}>
                                        {t('jobDetail:set_default_for_all_invoices')}
                                    </p>
                                </li>
                            ) : null}
                            {isShowSaveAs && (
                                <li className="items has-icon" onClick={onOpenSaveAs}>
                                    <IconFolder />
                                    <p className="txt-ellipsis">{t('jobDetail:save_note_as_template')}</p>
                                </li>
                            )}
                            <li>
                                <Link
                                    to={addBranchPath(pathTemplate.replace(':id', typeFilter))}
                                    className="items has-icon"
                                >
                                    <IconFille />
                                    <p className="txt-ellipsis">{t('jobDetail:manage_templates')}</p>
                                </Link>
                            </li>
                            {isInsertJobNote && !!!openTime && (
                                <li className="items has-icon" onClick={_insertJobNote}>
                                    <IconNote isAddNote />
                                    <p className="txt-ellipsis">{t('jobDetail:insert_from_an_existing_job_note')}</p>
                                </li>
                            )}
                            {isInsertInvoiceNote && (
                                <li
                                    className="items has-icon"
                                    onClick={(e) => _handleInsertNote(e, LIST_ACTIONS.INSERT_INVOICE_NOTE)}
                                >
                                    <IconNote isAddNote />
                                    <p className="txt-ellipsis">{t('jobDetail:insert_from_invoice_note')}</p>
                                </li>
                            )}
                            {isInsertWorkOrderNote && (
                                <li
                                    className="items has-icon"
                                    onClick={(e) => _handleInsertNote(e, LIST_ACTIONS.INSERT_WORK_ORDER_NOTE)}
                                >
                                    <IconNote isAddNote />
                                    <p className="txt-ellipsis">{t('jobDetail:insert_from_work_order_note')}</p>
                                </li>
                            )}
                        </ul>
                    )}

                    <div className="flex-auto header-search flexcenter">
                        <SearchOption
                            timeDdebound={500}
                            isSearchIcon
                            parentStyle={classNames('search-input', { 'w-100': isFilterPaymentTerms })}
                            defaultValue={''}
                            placeholder={t('jobDetail:search_templates')}
                            onSearch={_handleChangeSearch}
                        />
                        {finalIsVisible && isShowFilter && (
                            <Filter
                                listDataFilter={[...NOTES_TEMPLATE_OPTIONS, PAYMENT_TERMS_OPTION]}
                                filterParent={finalDataFilter}
                                onApplyFilter={_handleApplyFilter}
                            />
                        )}
                    </div>
                    {finalIsVisible && (
                        <ul className="box-auto scrolls" onScroll={handleOnScrollContent}>
                            {_renderTemplates()}
                        </ul>
                    )}
                    {isLoading && <Loading />}
                </div>
            </div>
            <ModalAddTerms ref={refAddTerms} onAddData={_handleAddTemplate} />
            <GdConfirm
                ref={refConfirm}
                title={t('common:confirm')}
                titleConfirm={t('common:confirm')}
                listButton={{ confirm: true, cancel: false }}
            />
        </div>
    );
}

const TemplateItem = ({
    item = {},
    tagTemplate = '',
    typeTemplate = '',
    itemClassName,
    strategy,
    onSelect = () => {}
}) => {
    const [iOpen, setIsOpen] = useState(false);
    const refButton = useRef(null);
    const refDropdown = useRef(null);

    const { t } = useTranslation(['setting']);

    const _open = () => {
        if (!item.id) return;
        setIsOpen(true);
    };

    const _close = () => {
        setIsOpen(false);
    };

    const setButtonRef = useCallback((node, ref) => {
        refButton.current = node;
        return ref(node);
    }, []);

    const setListRef = useCallback((node, ref) => {
        refDropdown.current = node;
        return ref(node);
    }, []);

    const _handleSelect = () => {
        onSelect(item);
        _close();
    };

    const _getModifiers = () => {
        const modifiers = [{ name: 'offset', options: { offset: [10, 1] } }];
        if (typeTemplate === TEMPLATE_KEYWORD.SMS && !!refButton.current) {
            const container = refButton.current.closest('.sms-preview .wrap-conversation-contents.scrolls');
            if (!container) return modifiers;
            const headerTop = refButton.current
                .closest('.conversation-details')
                .querySelector('.header-noti')
                .getBoundingClientRect().top;
            const buttonTop = refButton.current.getBoundingClientRect().top;
            modifiers.push({
                name: 'preventOverflow',
                options: {
                    boundary: container,
                    tetherOffset: ({ popper }) => (buttonTop - headerTop < popper.height ? 0 : popper.height)
                }
            });
        }

        return modifiers;
    };

    const { name: itemName, content: itemContent, type: itemType, attachments = [] } = item;

    const _renderAttachments = () => {
        if (typeTemplate !== TEMPLATE_KEYWORD.SMS || !attachments.length) return null;

        return (
            <div className="wrapbox-editor__form-attachment">
                <div className="file-attachment scrolls-x">
                    <div className="file-attachment-conts">
                        <GDAttachments data={attachments} type="sms" isHideClose />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <li
                        ref={(node) => setButtonRef(node, ref)}
                        onMouseOver={_open}
                        onMouseLeave={_close}
                        onClick={_handleSelect}
                        className={itemClassName}
                    >
                        <TemplateName type={itemType} {...item} isHeader={false} />
                        <span className="status-btn --sm bg-granite-gray">
                            {t(getTitleTemplate({ title: tagTemplate, value: itemType }))}
                        </span>
                        {iOpen ? (
                            <Popper placement={'left-end'} strategy={strategy} modifiers={_getModifiers()}>
                                {({ ref, style }) => (
                                    <div
                                        ref={(node) => setListRef(node, ref)}
                                        className={'boxs-details'}
                                        data-placement={'left'}
                                        role="tooltip"
                                        style={style}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <div className="boxs-details__header px-3 py-2">
                                            <h3 className="fs-17 word-break">{itemName}</h3>
                                        </div>
                                        {_renderAttachments()}
                                        <div
                                            className="boxs-details__content wrap-content p-3 scrolls"
                                            dangerouslySetInnerHTML={{ __html: itemContent }}
                                        />

                                        <div className="boxs-details__footer text-right p-3">
                                            <div onClick={_handleSelect} className="v2-btn-main">
                                                {t('use_template')}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Popper>
                        ) : null}
                    </li>
                )}
            </Reference>
        </Manager>
    );
};
