import { ACCESS_TOKEN, COMMON, DEFAULT_ALL, MATERIAL_DETAIL_OPTION, MATERIAL_USE_SELECT_OPTIONS } from 'app/const/App';
import classNames from 'classnames';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconPrint from 'assets/icon/IconPrint';
import PropTypes from 'prop-types';
import { LIST_EXPORT } from 'app/const/report/Common';
import {
    CUSTOMERS_COLUMNS_OPTIONS_ALL,
    SERVICE_LOOKUP_COLUMNS_SELECT_ALL,
    SUBSCRIPTION_COLUMNS_SELECT_ALL,
    SQUARE_SUBSCRIPTION_COLUMNS_SELECT_ALL,
    INBOUND_LEADS_COLUMNS_SELECT_ALL,
    NEW_JOB_COLUMNS_SELECT_ALL,
    DEVICE_TRACKING_COLUMNS_SELECT_ALL
} from 'app/const/report/ReportFilter';
import { EXPORT_LIMIT, REPORT_STATUS_STYLE, SIGNATURE_STATUS } from 'app/const/Reports';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import { getBranchId } from 'app/const/Branch';
import { getOrderSentriconReport } from '../sentricon/utils';

const Export = ({
    title,
    activePrint,
    isDisable,
    params,
    url,
    pageExport,
    refresh = 0,
    totalIds = 0,
    onGetData = () => [],
    onExport = () => {}
}) => {
    const { t } = useTranslation(['report']);
    const { smsplivo, quickbooks } = useSelector(({ auth }) => auth?.user?.settings?.addons);

    const finalParams = useMemo(() => {
        if (params?.columns && Array.isArray(params.columns)) {
            params['columns'] = params['columns'].filter((item) => {
                if (!quickbooks && (item === 'qb_sync' || item === 'qb_id')) return false;
                if (!smsplivo && item === 'sms') return false;
                return true;
            });
        }
        return params || {};
    }, [refresh]);

    const isDisableExport = isDisable || !finalParams || !url;
    const columns = params?.columns || [];
    const paramSignStatus = finalParams?.sign_status || DEFAULT_ALL;

    if (Array.isArray(paramSignStatus)) {
        const indexStatus = paramSignStatus.findIndex((status) => status === SIGNATURE_STATUS);

        if (indexStatus !== -1) {
            paramSignStatus[indexStatus] = REPORT_STATUS_STYLE.draft;
        }
    }
    finalParams.sign_status = paramSignStatus.toString();

    if (Array.isArray(paramSignStatus)) {
        const indexStatus = paramSignStatus.findIndex((status) => status === SIGNATURE_STATUS);

        if (indexStatus !== -1) {
            paramSignStatus[indexStatus] = REPORT_STATUS_STYLE.draft;
        }
    }

    finalParams.sign_status = paramSignStatus.toString();

    const _handleExport = (type) => {
        if (totalIds >= EXPORT_LIMIT) {
            onExport({ type, params: finalParams });
            return;
        }
        const token = getLocalStorageValue(ACCESS_TOKEN);
        const form = document.createElement('form');
        form.action = url;
        form.method = 'POST';
        form.id = title;
        form.style.display = 'none';

        switch (pageExport) {
            case LIST_EXPORT.EXPORT_REPORT:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="type" value="${type}">
                    <input name="columns" value="${columns.toString()}">
                    <input name="status" value="${finalParams.status}">
                    <input name="sign_status" value="${finalParams.sign_status}">
                    <input name="service" value="${finalParams.service}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="limit" value="${finalParams.limit}">
                    <input name="offset" value="${finalParams.offset}">
                    <input name="order" value="${finalParams.order}">
                    <input name="total" value="${finalParams.total || 1}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_PAYMENTS:
                form.innerHTML = `
                        <input name="token" value="${token}">
                        <input name="start" value="${finalParams.start}">
                        <input name="end" value="${finalParams.end}">
                        <input name="type" value="${type}">
                        <input name="columns" value="${columns.toString()}">
                        <input name="keyword" value="${finalParams.keyword}">
                        <input name="order" value="${finalParams.order}">
                        <input name="payment_methods" value="${finalParams.payment_methods}">
                        <input name="total" value="${finalParams.total || 1}">
                    `;
                break;

            case LIST_EXPORT.EXPORT_REPORT_INVOICES:
                form.innerHTML = `
                        <input name="token" value="${token}">
                        <input name="start" value="${finalParams.start}">
                        <input name="end" value="${finalParams.end}">
                        <input name="type" value="${type}">
                        <input name="columns" value="${columns.toString()}">
                        <input name="status" value="${finalParams.status}">
                        <input name="sign_status" value="${finalParams.sign_status}">
                        <input name="service" value="${finalParams.service}">
                        <input name="keyword" value="${finalParams.keyword}">
                        <input name="order" value="${finalParams.order}">
                        <input name="archived" value="${finalParams.archived}">
                        <input name="deleted" value="${finalParams.deleted}">
                        <input name="invoice_tags" value="${finalParams.invoice_tags || -1}">
                        <input name="total" value="${finalParams.total || 1}">
                    `;
                break;

            case LIST_EXPORT.EXPORT_CUSTOMER:
                const dataIdsCustomer = onGetData() || [];
                const ids = dataIdsCustomer.map((item) => item?.id || item).toString();
                const finalColumnsCustomer = CUSTOMERS_COLUMNS_OPTIONS_ALL.filter((col) => columns.includes(col));
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="ids" value="${ids}">
                    <input name="columns" value="${finalColumnsCustomer.toString()}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="deleted" value="${finalParams.deleted}">
                    <input name="character" value="${finalParams.character}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="order" value="${finalParams.order}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="status" value="${finalParams.status.toString()}">
                    <input name="tag" value="${finalParams.tags.toString()}">
                    <input name="expired" value="${finalParams.expired || 0}">
                    <input name="expiring" value="${finalParams.expiring || 0}">
                    <input name="bounced" value="${finalParams.bounced || 0}">
                    <input name="lead" value="${finalParams.lead || 0}">
                    <input name="wo_service" value="${finalParams.wo_service || 0}">
                    <input name="w_service" value="${finalParams.w_service || 0}">
                    <input name="bounced_phone" value="${finalParams.bounced_phone || 0}">
                    <input name="sub_locations" value="${finalParams.sub_locations || 0}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_SERVICE_LOOKUP:
                let newColumns = ['customer', ...columns];
                newColumns = SERVICE_LOOKUP_COLUMNS_SELECT_ALL.filter((col) => newColumns.includes(col));

                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="type" value="${type}">
                    <input name="columns" value="${newColumns.toString()}">
                    <input name="job_status" value="${finalParams.job_status}">
                    <input name="assigned_to" value="${finalParams.assigned_to}">
                    <input name="service" value="${finalParams.service}">
                    <input name="city" value="${finalParams.city}">
                    <input name="zipcode" value="${finalParams.zipcode}">
                    <input name="tags" value="${finalParams.tags}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="order" value="${finalParams.order}">
                    <input name="${COMMON.JOB_STATE}" value="${finalParams[COMMON.JOB_STATE] || COMMON.ACTIVE}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_CUSTOMER_WO_ACTIVE:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="type" value="${type}">
                    <input name="status" value="${finalParams.status}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="service" value="${finalParams.service_type_single}">
                    <input name="order" value="${finalParams.order || ''}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_LOCATION_WO_ACTIVE:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="type" value="${type}">
                    <input name="status" value="${finalParams.status}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="order" value="${finalParams.order || ''}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_ACCOUNT_AGING:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="period_ending" value="${finalParams.period_ending}">
                    <input name="filters" value="${finalParams.filters}">
                    <input name="email" value="${finalParams.email}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="order" value="${finalParams.order}">
                    <input name="service" value="${finalParams.service}">
                    <input name="columns" value="${columns.toString()}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_PAYMENT_COLLECTED:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="order" value="${finalParams.order}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="payment_for" value="${finalParams.payment_for}">
                    <input name="clients" value="${finalParams.customer_id}">
                    <input name="staff" value="${finalParams.staff}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="total" value="1">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_TOTAL_SALES:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="order" value="${finalParams.order?.replace('invoice_number', 'invoice_id')}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="client" value="${finalParams.customer_id}">
                    <input name="total" value="1">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_SALES_FORECAST:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="year" value="${finalParams.year}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="order" value="${finalParams.order}">
                    <input name="total" value="1">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_SALES_TAXES:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="revenue" value="${finalParams.revenue}">
                    <input name="order" value="${finalParams.order}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_TAXES_RATE:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="revenue" value="${finalParams.revenue}">
                    <input name="order" value="${finalParams.order}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_REVENUE_CLIENT:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="year" value="${finalParams.year}">
                    <input name="clients" value="${finalParams.clients}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="order" value="${finalParams.order}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="total" value="${finalParams.total || 1}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_REVENUE_SERVICE:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="year" value="${finalParams.year}">
                    <input name="service" value="${finalParams.service}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="order" value="${finalParams.order}">
                    <input name="total" value="${finalParams.total || 1}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_REVENUE_ITEM:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="year" value="${finalParams.year}">
                    <input name="item" value="${finalParams.item}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="order" value="${finalParams.order}">
                    <input name="total" value="${finalParams.total || 1}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_REVENUE_STAFF:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="year" value="${finalParams.year}">
                    <input name="staff" value="${finalParams.staff}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="order" value="${finalParams.order}">
                    <input name="total" value="${finalParams.total || 1}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_SOURCE:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="year" value="${finalParams.year}">
                    <input name="source" value="${finalParams.source}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="order" value="${finalParams.order}">
                    <input name="total" value="${finalParams.total || 1}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_TAGS:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="year" value="${finalParams.year}">
                    <input name="tag_type" value="${finalParams.tag_type}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="order" value="${finalParams.order}">
                    <input name="total" value="${finalParams.total || 1}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_MATERIAL_USE:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="county" value="${finalParams.county}">
                    <input name="order" value="${finalParams.order}">
                    <input name="clients" value="${finalParams.clients}">
                    <input name="materials" value="${finalParams.materials}">
                    <input name="limit" value="0">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_MATERIAL_USE_DETAIL:
                const finalColumnMaterial =
                    finalParams?.county === MATERIAL_USE_SELECT_OPTIONS.NY_PRL
                        ? finalParams.columns_ny
                        : finalParams.columns;
                form.innerHTML = `
                        <input name="token" value="${token}">
                        <input name="type" value="${type}">
                        <input name="start" value="${finalParams.start}">
                        <input name="end" value="${finalParams.end}">
                        <input name="county" value="-1">
                        <input name="order" value="${finalParams.order}">
                        <input name="columns" value="${finalColumnMaterial.toString()}">
                        <input name="total" value="1">
                        <input name="option" value="${MATERIAL_DETAIL_OPTION[finalParams?.county]}">
                        <input name="clients" value="${finalParams.clients}">
                        <input name="materials" value="${finalParams.materials}">
                        <input name="limit" value="0">
                    `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_EMAIL_HISTORY:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="status" value="${finalParams.status}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="customer_id" value="${finalParams.customer_id || ''}">
                    <input name="order" value="${finalParams.order || ''}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_RECURRING_INVOICE:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="status" value="${finalParams.status}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="columns" value="${columns}">
                    <input name="order" value="${finalParams.order || ''}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.STRIPE]:
                let newColumnsSubscriptions = ['customer', ...columns];
                newColumnsSubscriptions = SUBSCRIPTION_COLUMNS_SELECT_ALL.filter((col) =>
                    newColumnsSubscriptions.includes(col)
                );
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="status" value="${finalParams.status}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="plans" value="${finalParams.plans}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="columns" value="${newColumnsSubscriptions.toString()}">
                    <input name="techs" value="${finalParams.staffs.toString()}">
                    <input name="subscription_type" value="${PAYMENT_CARD_CUSTOMER.STRIPE}">
                    <input name="order" value="${finalParams.order || ''}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.SQUARE]:
                let newColumnsSquareSubscriptions = ['customer', ...columns];
                newColumnsSquareSubscriptions = SQUARE_SUBSCRIPTION_COLUMNS_SELECT_ALL.filter((col) =>
                    newColumnsSquareSubscriptions.includes(col)
                );
                form.innerHTML = `
                        <input name="token" value="${token}">
                        <input name="type" value="${type}">
                        <input name="merchant" value="${finalParams.merchant || 2}">
                        <input name="status" value="${finalParams.status}">
                        <input name="keyword" value="${finalParams.keyword}">
                        <input name="sort_by" value="${finalParams.sort_by}">
                        <input name="plans" value="${finalParams.square_plans}">
                        <input name="total" value="${finalParams.total || 1}">
                        <input name="columns" value="${newColumnsSquareSubscriptions.toString()}">
                        <input name="techs" value="${finalParams.staffs.toString()}">
                        <input name="subscription_type" value="${PAYMENT_CARD_CUSTOMER.SQUARE}">
                        <input name="order" value="${finalParams.order || ''}">
                    `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_COMMISSION:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="display_by" value="${finalParams.display_by}">
                    <input name="commission" value="${finalParams.commission}">
                    <input name="staffs" value="${finalParams.staffs}">
                    <input name="invoice_status" value="${finalParams.invoice_status}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="order" value="${finalParams.order?.replace(/invoice_date|payment_date/, 'date')}">
                    <input name="report_type" value="${finalParams.report_type}">
                    <input name="job_status" value="${finalParams.job_status}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_LEAD:
                const finalColumns = INBOUND_LEADS_COLUMNS_SELECT_ALL.filter((col) => columns.includes(col));

                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="order" value="${finalParams.order}">
                    <input name="source" value="${finalParams.source_inbound || finalParams.source}">
                    <input name="columns" value="${finalColumns.toString()}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_REVENUE_CLIENT_DETAIL:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="payment_for" value="${finalParams.payment_for}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="clients" value="${finalParams.clients}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="order" value="${finalParams.order}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_REVENUE_SERVICE_DETAIL:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="payment_for" value="${finalParams.payment_for}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="service" value="${finalParams.service}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="order" value="${finalParams.order}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_REVENUE_ITEMS:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="year" value="${finalParams.year}">
                    <input name="payment_for" value="${finalParams.payment_for}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="item" value="${finalParams.item}">
                    <input name="service" value="${finalParams.service}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="order" value="${finalParams.order}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_REVENUE_ITEMS_DETAIL:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="payment_for" value="${finalParams.payment_for}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="item" value="${finalParams.item === -1 ? -5 : finalParams.item}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="order" value="${finalParams.order}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_NEW_CUSTOMERS:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="report_source" value="${finalParams.report_source || DEFAULT_ALL}">
                    <input name="order" value="${finalParams.order}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                    <input name="total" value="${finalParams.total || 1}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_ESTIMATE:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="type" value="${type}">
                    <input name="archived" value="${finalParams.archived}">
                    <input name="deleted" value="${finalParams.deleted}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="order" value="${finalParams.order}">
                    <input name="status" value="${finalParams.status}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="sign_status" value="${finalParams.sign_status}">
                    <input name="columns" value="${columns.map((column) => column).toString()}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_DOCUMENT:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="type" value="${type}">
                    <input name="archived" value="${finalParams.archived}">
                    <input name="deleted" value="${finalParams.deleted}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="order" value="${finalParams.order}">
                    <input name="status" value="${finalParams.status}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="columns" value="${columns.map((column) => column).toString()}">
                    <input name="types" value="${finalParams.types}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_CREDIT:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="type" value="${type}">
                    <input name="keyword" value="${finalParams.keyword}">
                    <input name="order" value="${finalParams.order}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="columns" value="${columns.map((column) => column).toString()}">
                `;
                break;
            case LIST_EXPORT.REVIEW_SERVICE:
                form.innerHTML = `
                    <input name="type" value="${type}" type="text">
                    <input name="token" value="${token}" type="text">
                    <input name="start" value="${finalParams.start}" type="text">
                    <input name="end" value="${finalParams.end}" type="text">
                    <input name="items" value="${finalParams.items}" type="text">
                    <input name="order" value="${finalParams.order}" type="text">
                    <input name="users" value="${finalParams.users}" type="text">
                    `;
                break;
            case LIST_EXPORT.REVIEW_LIKELY:
                form.innerHTML = `
                    <input name="type" value="${type}" type="text">
                    <input name="token" value="${token}" type="text">
                    <input name="start" value="${finalParams.start}" type="text">
                    <input name="end" value="${finalParams.end}" type="text">
                    <input name="items" value="${finalParams.items}" type="text">
                    <input name="order" value="${finalParams.order}" type="text">
                    `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_TAGS_DETAIL:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="tag_type" value="${finalParams.tag_type}">
                    <input name="payment_for" value="${finalParams.payment_for}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="tag_id" value="${finalParams.tag_id}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="order" value="${finalParams.order}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_REVENUE_STAFF_DETAIL:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="staff" value="${finalParams.staff}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="payment_for" value="${finalParams.payment_for}">
                    <input name="order" value="${finalParams.order}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_SOURCE_DETAIL:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="source" value="${finalParams.source}">
                    <input name="sale" value="${finalParams.sale}">
                    <input name="payment_methods" value="${finalParams.payment_methods}">
                    <input name="total" value="${finalParams.total || 1}">
                    <input name="order" value="${finalParams.order}">
                `;
                break;
            case LIST_EXPORT.EXPORT_LOCATIONS_CUSTOMER:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="columns" value="${finalParams.columns}">
                    <input name="order" value="${finalParams.order}">
                `;
                break;
            case LIST_EXPORT.EXPORT_REPORT_LEAD_ONLINE_BOOKING:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="order" value="${finalParams.order}">
                    <input name="source" value="${finalParams.source_online_booking || finalParams.source}">
                    <input name="columns" value="${columns}">
                    <input name="sort_by" value="${finalParams.sort_by}">
                `;
                break;
            case LIST_EXPORT.EXPORT_ACTIVITY_COMPARISON:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="order" value="${finalParams.order || ''}">
                    <input name="users" value="${finalParams.staffs}">
                    <input name="columns" value="${columns}">
                `;
                break;
            case LIST_EXPORT.EXPORT_STATUS_CHANGES:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="staffs" value="${finalParams.staffs}">
                    <input name="type" value="${type}">
                `;
                break;
            case LIST_EXPORT.EXPORT_WORK_LOG:
                form.innerHTML = `
                    <input name="token" value="${token}">
                    <input name="type" value="${type}">
                    <input name="start" value="${finalParams.start}">
                    <input name="end" value="${finalParams.end}">
                    <input name="user_ids" value="${finalParams.staffs}">
                `;
                break;
            case LIST_EXPORT.EXPORT_NEW_JOB:
                let newColumnsNewJob = ['customer', ...columns];
                newColumnsNewJob = NEW_JOB_COLUMNS_SELECT_ALL.filter((col) => newColumnsNewJob.includes(col));

                form.innerHTML = `
                        <input name="token" value="${token}">
                        <input name="type" value="${type}">
                        <input name="start" value="${finalParams.start}">
                        <input name="end" value="${finalParams.end}">
                        <input name="order" value="${finalParams.order}">
                        <input name="columns" value="${newColumnsNewJob.toString()}">
                        <input name="service" value="${finalParams.service}">
                        <input name="job_status" value="${finalParams.job_status}">
                        <input name="assigned_to" value="${finalParams.assigned_to}">
                        <input name="report_source" value="${finalParams.report_source}">
                    `;
                break;
            case LIST_EXPORT.EXPORT_CALL_ACTIVITY:
                form.innerHTML = `
                        <input name="token" value="${token}">
                        <input name="type" value="${type}">
                        <input name="id" value="${finalParams.id}">
                        <input name="call_type" value="${finalParams.call_type}">
                        <input name="start" value="${finalParams.start}">
                        <input name="end" value="${finalParams.end}">
                        <input name="order" value="${finalParams.order || ''}">
                        <input name="total" value="1">
                    `;
                break;
            case LIST_EXPORT.EXPORT_DEVICE_TRACKING:
                const newColumnsDeviceTracking = DEVICE_TRACKING_COLUMNS_SELECT_ALL.filter((col) =>
                    columns.includes(col)
                );
                form.innerHTML = `
                        <input name="token" value="${token}">
                        <input name="type" value="${type}">
                        <input name="columns" value="${newColumnsDeviceTracking.toString()}">
                        <input name="start" value="${finalParams.start}">
                        <input name="end" value="${finalParams.end}">
                        <input name="order" value="${finalParams.order || ''}">
                        <input name="clients" value="${finalParams.clients || DEFAULT_ALL}">
                        <input name="total" value="1">
                    `;
                break;
            case LIST_EXPORT.EXPORT_SENTRICON:
                form.innerHTML = `
                        <input name="token" value="${token}">
                        <input name="type" value="${type}">
                        <input name="total" value="1">
                        <input name="start" value="${finalParams.start}">
                        <input name="end" value="${finalParams.end}">
                        <input name="order" value="${getOrderSentriconReport(finalParams.order)}">
                    `;
                break;
            default:
                break;
        }
        form.innerHTML += `<input name="gd-branch-id" value="${getBranchId()}">`;
        form.setAttribute('target', '_blank');
        document.body.append(form);
        form.submit();
        const elements = document.getElementById(title);
        elements.parentNode.removeChild(elements);
    };

    return (
        <div className="export flexcenter gap-4 fw-500 fs-13">
            <p>{title}:</p>
            <div className="flexcenter">
                <div
                    className={classNames('export__option --blue --left', { 'is-disable': isDisableExport })}
                    onClick={() => _handleExport(COMMON.CSV)}
                >
                    {t('report:csv')}
                </div>
                <div
                    className={classNames('export__option --blue --right', { 'is-disable': isDisableExport })}
                    onClick={() => _handleExport(COMMON.XLS)}
                >
                    {t('report:excel')}
                </div>
            </div>
            {activePrint && (
                <div
                    className={classNames('v2-btn-default has-icon --sm', {
                        'is-disable': isDisableExport
                    })}
                    onClick={() => _handleExport(COMMON.PRINT)}
                >
                    <IconPrint />
                    {t('report:print')}
                </div>
            )}
        </div>
    );
};

Export.propTypes = {
    title: PropTypes.string,
    activePrint: PropTypes.bool,
    isDisable: PropTypes.bool,
    finalParams: PropTypes.object,
    url: PropTypes.string
};
Export.defaultProp = {
    title: '',
    finalParams: null,
    url: ''
};

export const generateTitleExport = (totalItems, translation, title = 'row') => {
    const { t } = useTranslation([`${translation}`]);
    return t(`${translation}:export`)
        .concat(' ', totalItems)
        .concat(
            ' ',
            totalItems > 1 ? t(`${translation}:${title}s`).toLowerCase() : t(`${translation}:${title}`).toLowerCase()
        );
};

export default Export;
