import classNames from 'classnames';
import React, { Fragment, useEffect, useRef } from 'react';

import LoadingDefault from './LoadingDefault';
import { VIDEO_PLAYER_ACTIONS, WISTIA_EMBED_SCRIPT_URL } from './constant';
import { calculateViewPercentage } from './utils';

const GDWistiaPlayer = ({
    className = '',
    videoId = '',
    isAutoPlay = false,
    isLoading = false,
    loadingComponent: LoadingComponent = LoadingDefault,
    onLoadComplete = () => {},
    onTracking = () => {}
}) => {
    const refWistiaPlayer = useRef(null);

    useEffect(() => {
        if (!videoId) return;
        const scriptId = 'wistia-script';
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = WISTIA_EMBED_SCRIPT_URL;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
        }
        const _cleanWistiaPlayerInstance = () => {
            if (refWistiaPlayer.current) {
                refWistiaPlayer.current.unbind(VIDEO_PLAYER_ACTIONS.PLAY);
                refWistiaPlayer.current.unbind(VIDEO_PLAYER_ACTIONS.PAUSE);
                refWistiaPlayer.current = null;
            }
        };
        _cleanWistiaPlayerInstance();

        window._wq = window._wq || [];
        window._wq.push({
            id: videoId,
            options: { autoPlay: isAutoPlay },
            onReady: (video) => {
                refWistiaPlayer.current = video;
                video.ready(() => {
                    onLoadComplete();
                });
                const trackViewPercentage = (isPressPlay = true) => {
                    onTracking({
                        percent_view: calculateViewPercentage({ time: video.time(), duration: video.duration() }),
                        press_play: isPressPlay
                    });
                };
                video.bind(VIDEO_PLAYER_ACTIONS.PLAY, () => {
                    trackViewPercentage(true);
                });
                video.bind(VIDEO_PLAYER_ACTIONS.PAUSE, () => {
                    trackViewPercentage(false);
                });
            }
        });

        return () => {
            _cleanWistiaPlayerInstance();
        };
    }, [videoId]);

    return (
        <Fragment>
            {isLoading ? <LoadingComponent /> : null}
            <div
                key={videoId}
                className={classNames(`wistia_embed wistia_async_${videoId}`, className, { 'h-0': isLoading })}
            />
        </Fragment>
    );
};

export default GDWistiaPlayer;
