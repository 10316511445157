/* eslint-disable no-undef */
import GDPreviewAttach from 'app/components/attachments/GDPreviewAttach';
import LoadingSmsList from 'app/components/loading/sms/LoadingSmsList';
import { RE_SEND_SMS_BY_CUSTOMER, SEND_SMS_BY_CUSTOMER } from 'app/const/api/Sms';
import {
    NOT_RECEIVER_PHONE_DELETED,
    READ_STATUS,
    SMS_DECREMENT_TOTAL,
    SMS_FAILED,
    SMS_INCREMENT_TOTAL,
    SMS_SENDING,
    SMS_SUCCEEDED,
    SMS_TYPE_USERS_SENDER,
    STRING_TODAY,
    UNREAD_STATUS,
    DEFAULT_PHONES,
    DEFAULT_SMS,
    MODULE_SMS,
    PDF_TYPE,
    CONVERSATION_TYPE
} from 'app/const/Sms';
import ConversationContent from 'app/modules/sms/components/ConversationContent';
import SmsDetailHeader from 'app/modules/sms/components/ConversationHeader';
import { addNewBookMarkAction } from 'common/redux/actions/calendar/bookMarkAction';
import { smsGetListByCustomer, smsGetListPhones, smsUpdateStatus } from 'common/redux/actions/sms/smsAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { filesEventToData, isFileImage, isFileVideo } from 'common/utils/FileUtils';
import moment from 'moment';
import React, { useEffect, useRef, useImperativeHandle, forwardRef, useReducer, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MessageBox from 'app/modules/sms/customer/components/MessageBox';
import RealTimeConverDetail from '../service/RealTimeConverDetail';
import { convertWsReceiveToSms } from '../utils';
import { reducer } from 'app/const/Reducer';
import LoadMore from './LoadMore';
import classNames from 'classnames';
import RichTextDropzone from 'app/components/richtext/components/RichTextDropzone';
import { ACCEPTED_ATTACHMENTS, ACCOUNT_ROLE } from 'app/const/App';
import { checkPermission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';
import { KEY_GET_NUMBER_SELECTED_INBOX, TYPE_SHOW_SMS } from 'app/modules/inbox/const';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { useTranslation } from 'react-i18next';
import { actionDeleteSms } from 'app/const/api/V2';

const ConversationDetail = forwardRef(
    (
        {
            isJobPreview = false,
            socketReceiver,
            socketConversation,
            customer,
            setStep,
            onUpdateTotal = () => {},
            onClose = () => {},
            jobId = '',
            isActive = true,
            isUnknow = false,
            autoOpenBoxMessage = false,
            onCreateLead = () => {},
            onAssignLead = () => {},
            conversationId = '',
            isShowHeader = true,
            type = TYPE_SHOW_SMS.HEADER,
            isPhoneAction = false,
            isOutboxStatus = false
        },
        ref
    ) => {
        let dragTimer;
        const { t } = useTranslation('customers');
        const dispatch = useDispatch();

        const modalAttachmentRef = useRef(null);
        const refPreScroll = useRef(0);
        const refWrapper = useRef(null);
        const refContainer = useRef(null);
        const refContentSMs = useRef(null);
        const refListSMS = useRef(null);
        const refMessageBox = useRef(null);
        const refPreviewAttach = useRef(null);
        const refCanScroll = useRef(true);
        const refLoadMore = useRef(null);
        const refDropWarning = useRef(null);
        const refConfirm = useRef(null);

        const profile = useSelector(({ auth }) => auth.user.profile) || {};

        const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
        const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);
        const bookMarkList = useSelector((state) => state.bookMarkReducer.list);
        const listSaveApplyContact = [TYPE_SHOW_SMS.HEADER, TYPE_SHOW_SMS.JOB];
        const isSupperAdmin = profile?.role === ACCOUNT_ROLE.SUPERADMIN;

        const [state, dispatchAction] = useReducer(reducer, {
            phones: DEFAULT_PHONES,
            sms: DEFAULT_SMS,
            users: {
                selected: companyUsers,
                list: companyUsers
            },
            refeshScreen: 0,
            default_sender: '',
            customerInfo: {}
        });

        const { sms, users, phones, refeshScreen, default_sender: defaultSender, customerInfo } = state;
        const isUseDataApply = !jobId && listSaveApplyContact.includes(type) && !isPhoneAction;

        useImperativeHandle(ref, () => ({
            reset: () => {
                dispatchAction({
                    phones: DEFAULT_PHONES,
                    sms: DEFAULT_SMS
                });
            },
            updateBookMarkOnCLose: _handleUpdateExistBookMark
        }));

        useEffect(() => {
            document.addEventListener('dragover', _handleDragOver);
            document.addEventListener('dragleave', _handleDragEnd);

            return () => {
                document.removeEventListener('dragover', _handleDragOver);
                document.removeEventListener('dragleave', _handleDragEnd);
            };
        }, []);

        const finalSocketId = useMemo(() => {
            switch (type) {
                case TYPE_SHOW_SMS.HEADER:
                    return global.conversationListSocketId;
                case TYPE_SHOW_SMS.JOB:
                    return global.conversationJobSocketId;
                case TYPE_SHOW_SMS.INBOX:
                    return global.inboxSmsSocketId;
                default:
                    return global.conversationListSocketId;
            }
        }, [sms]);

        /**
         * Handle drag image over window
         */
        const _handleDragOver = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (refDropWarning.current) refDropWarning.current.handleDisplay();
            window.clearTimeout(dragTimer);
        };

        const _handleDragEnd = (e) => {
            e.preventDefault();
            e.stopPropagation();
            dragTimer = window.setTimeout(() => {
                if (refDropWarning.current) refDropWarning.current.handleHidden();
            }, 25);
        };

        const {
            id: finalCustomerId,
            default_phone: customerDefaultPhone,
            filter_phone: customerFilterPhone
        } = customer;

        const { isLoading: finalSmsIsLoading, list: finalListSms = [], show_more: finalShowMore } = sms;
        const { list: finalListPhones = [], fetched: phonesFetched, triggerSms } = phones;
        const { selected: listUserSelected } = users;

        const _convertContactTocustomer = (newCustomerPhone, oldCustomer) => {
            const { first_name, last_name, isCustomerContact } = newCustomerPhone;
            return {
                ...oldCustomer,
                full_name: isCustomerContact ? first_name.concat(' ', last_name) : oldCustomer.full_name,
                avatar: isCustomerContact
                    ? first_name.substring(0, 1).concat(last_name?.substring(0, 1) || '')
                    : oldCustomer.avatar
            };
        };

        useEffect(() => {
            if (!isUnknow && !finalSmsIsLoading) {
                const newCustomerPhone = customer.phone || {};

                dispatchAction((prevState) => {
                    return {
                        ...prevState,
                        sms: {
                            ...prevState.sms,
                            list: prevState.sms.list.map((item) => {
                                return {
                                    ...item,
                                    customer: _convertContactTocustomer(newCustomerPhone, {
                                        ...item.customer,
                                        ...customer
                                    }),
                                    phone: customer.phone
                                };
                            })
                        }
                    };
                });
                getListPhone(false);
            }
        }, [isUnknow]);

        useEffect(() => {
            !isUnknow && finalCustomerId && getListPhone(true);
        }, []);

        // Handle case open detail from customer opportunity
        const refDefaultPhone = useRef(customerDefaultPhone);
        useEffect(() => {
            if (refDefaultPhone.current !== customerDefaultPhone && finalCustomerId && isActive) {
                refDefaultPhone.current = customerDefaultPhone;
                getListPhone(true);
            }
        }, [customerDefaultPhone]);

        useEffect(() => {
            refPreScroll.current = null;
            if ((phonesFetched && finalCustomerId && triggerSms) || isUnknow) {
                getListSms(0, finalListPhones, listUserSelected);
            }
        }, [phonesFetched, listUserSelected, refeshScreen]);

        useEffect(() => {
            if (!finalSmsIsLoading && refCanScroll.current) {
                scrollToBottom();
                refCanScroll.current = false;
            }
        }, [finalListSms]);

        useEffect(() => {
            if (socketReceiver) {
                const { sms_data } = socketReceiver || {};

                const isUnknow = sms_data.key === CONVERSATION_TYPE.UNKNOW;

                const compareCustomer =
                    (isUnknow ? customer.filter_phone === sms_data.filter_phone : true) &&
                    parseInt(socketReceiver?.customer?.customer_id) === parseInt(finalCustomerId);

                if (
                    !finalSmsIsLoading &&
                    compareCustomer &&
                    (!checkPermission(permissionsList, PERMISSIONS.smsFilterByUser) ||
                        socketReceiver?.unAssignSmsUsers?.includes(profile?.id))
                ) {
                    _addMessage(convertWsReceiveToSms(socketReceiver));
                }
            }
        }, [socketReceiver]);

        useEffect(() => {
            if (socketConversation && socketConversation?.socket_id !== finalSocketId) {
                const conversations = socketConversation.conversations;
                const currentConversation = conversations.find((item) => item.customer_id === finalCustomerId);

                if (!currentConversation) {
                    return;
                } else {
                    const messageIds = currentConversation.message_ids || [];

                    dispatchAction((prevState) => {
                        let newStateSms = [...(prevState.sms.list || [])];

                        newStateSms = newStateSms.map((item) => {
                            if (messageIds.includes(item.id)) {
                                return {
                                    ...item,
                                    is_read: Number(socketConversation.check) ? UNREAD_STATUS : READ_STATUS
                                };
                            }
                            return item;
                        });

                        return {
                            ...prevState,
                            sms: {
                                ...prevState.sms,
                                list: newStateSms
                            }
                        };
                    });
                }
            }
        }, [socketConversation]);

        const finalReciver = () => {
            if (isUnknow) {
                return [
                    {
                        key: Date.now(),
                        name: 'Unknown contact',
                        avatar: '',
                        phone: customerDefaultPhone,
                        value: customerDefaultPhone,
                        is_default: true,
                        is_valid: -1
                    }
                ];
            }
            return phones.receivers || [];
        };

        const _handleFetchFail = ({ message, isResetState = true }) => {
            if (isResetState) {
                dispatchAction((prevState) => ({
                    ...prevState,
                    phones: { ...prevState.phones, loading: false },
                    sms: { ...prevState.sms, isLoading: false }
                }));
            }

            _handleShowWarning({ description: message });
        };

        const getListPhone = (triggerSms) => {
            const defaultPhone = isUseDataApply
                ? getLocalStorage(KEY_GET_NUMBER_SELECTED_INBOX)?.[finalCustomerId]?.toString()
                : null;

            dispatch(
                smsGetListPhones(
                    {
                        customer_id: finalCustomerId,
                        default_phone: defaultPhone ?? customerDefaultPhone,
                        job_id: jobId
                    },
                    (response) => smsGetListPhonesSuccess({ response, triggerSms, isHaveDefaultPhone: !!defaultPhone }),
                    _handleFetchFail
                )
            );
        };

        const _removeLoadingLoadMore = () => {
            refLoadMore.current?.removeLoading();
        };

        const getListSms = (offset, listPhones, listUsers) => {
            const _getListSmsFailed = (err) => {
                _removeLoadingLoadMore();
                _handleFetchFail(err);
            };
            dispatch(
                smsGetListByCustomer(
                    {
                        customer_id: finalCustomerId,
                        offset,
                        limit: 30,
                        phones: isUnknow
                            ? ''
                            : listPhones
                                  .filter((customerPhone) => customerPhone.is_active)
                                  .map((item) => item.phone)
                                  .toString(),
                        users: isOutboxStatus ? undefined : listUsers.map((item) => item.id).toString(),
                        phone: isUnknow ? customerFilterPhone : ''
                    },
                    _getListSmsSuccess,
                    _getListSmsFailed
                )
            );
        };

        const scrollToBottom = () => {
            if (refContainer) {
                if (refPreScroll.current) {
                    refWrapper.current.scrollTop = refWrapper.current?.scrollHeight - refPreScroll.current;
                } else {
                    refWrapper.current.scrollTop = refContainer.current?.offsetHeight || 0;
                }
            }
        };

        const showModal = (url) => {
            modalAttachmentRef.current.showModal(url);
        };

        const _checkPhoneActive = (phoneSelected, lisFilter) => {
            const arrachCheck = phoneSelected.filter((e) => {
                return lisFilter.includes(e);
            });
            return !!arrachCheck.length;
        };

        const smsGetListPhonesSuccess = ({ response, triggerSms, isHaveDefaultPhone }) => {
            let phones = [...(response.phones || [])];
            const customerPhoneLocation = customer.phone_location;

            if (
                Array.isArray(customerPhoneLocation) &&
                customerPhoneLocation.length > 0 &&
                phones.length &&
                !isPhoneAction &&
                !isHaveDefaultPhone
            ) {
                phones = phones.map((item) => ({
                    ...item,
                    is_active: _checkPhoneActive(customerPhoneLocation, item.filter)
                }));
            }

            dispatchAction((prevState) => {
                return {
                    ...prevState,
                    phones: {
                        loading: false,
                        list: phones,
                        receivers: response.receivers || [],
                        fetched: true,
                        triggerSms
                    }
                };
            });
        };

        function _getListSmsSuccess(response) {
            const newAttachments = { images: [], pdf: [], videos: [] };
            const { data: responseData = [], customer_info: customerInfo } = response;

            responseData.forEach((el) => {
                el?.attachments?.forEach((element) => {
                    if (isFileImage(element.type)) newAttachments['images'].push(element);
                    if (isFileVideo(element.type)) newAttachments['videos'].push(element);
                    if (element.type === PDF_TYPE) newAttachments['pdf'].push(element);
                });
            });

            refCanScroll.current = true;

            dispatchAction((prevState) => {
                return {
                    ...prevState,
                    sms: {
                        isLoading: false,
                        list: [...prevState.sms.list, ...responseData],
                        attachments: newAttachments,
                        show_more: response.show_more
                    },
                    default_sender: response.default_sender || prevState.default_sender,
                    customerInfo
                };
            });

            _removeLoadingLoadMore();
        }

        const _handleSelectPhone = (data) => {
            if (isUseDataApply) {
                const selected = getLocalStorage(KEY_GET_NUMBER_SELECTED_INBOX) || {};
                const phoneSelected = [];
                data.forEach(({ is_active, phone }) => {
                    if (is_active) phoneSelected.push(phone);
                });
                selected[finalCustomerId] = phoneSelected;
                setLocalStorage(KEY_GET_NUMBER_SELECTED_INBOX, selected);
            }

            dispatchAction((prevState) => {
                return {
                    ...prevState,
                    phones: {
                        ...prevState.phones,
                        list: data
                    },
                    sms: DEFAULT_SMS,
                    refeshScreen: Date.now()
                };
            });
        };

        const _handleSelectedUsers = (data) => {
            dispatchAction((prevState) => {
                return {
                    ...prevState,
                    users: {
                        ...prevState.users,
                        selected: data
                    },
                    sms: DEFAULT_SMS,
                    refeshScreen: Date.now()
                };
            });
        };

        const _handleLoadMore = () => {
            refLoadMore.current && refLoadMore.current.setLoading(true);
            getListSms(finalListSms.length, finalListPhones, listUserSelected);
        };

        const _handleOnScroll = (e) => {
            e && e.stopPropagation();

            if (
                finalShowMore &&
                refWrapper.current.scrollTop < 50 &&
                !finalSmsIsLoading &&
                !refLoadMore.current?.getCurrent
            ) {
                refPreScroll.current = refWrapper.current.scrollHeight;
                _handleLoadMore();
            }
        };

        const _handleOpenConversationDetail = (action, value, type) => {
            switch (action) {
                case 'openDetail':
                    let dataView = sms?.attachments['images'];
                    if (type === PDF_TYPE) dataView = sms?.attachments['pdf'];
                    if (isFileVideo(type)) dataView = sms?.attachments['videos'];
                    refPreviewAttach.current.openPreviewWithAttach(dataView, value);
                    break;
                default:
                    break;
            }
        };

        const _checkShowMsg = (dataMsg) => {
            const { type, filter, isNewMessage, id: messageId = '' } = dataMsg;
            const isReceive = type === MODULE_SMS.TYPE_SMS.TO;

            const { user_id, sender_id, user_deleted, sender_deleted, phone_deleted, receiver_phone_deleted } =
                filter || {};

            const filterUserSelected =
                !![...users.selected].find(
                    (i) => i?.id === (isReceive ? user_id?.toString() : sender_id?.toString())
                ) || !!parseInt(isReceive ? user_deleted : sender_deleted);

            //Unknown don't have list phone
            const filterPhoneActive =
                !!finalListPhones.find(
                    (i) =>
                        i.is_active &&
                        !!i.filter.find((j) =>
                            isReceive ? filter?.phone?.includes(j) : filter?.receiver_phones?.includes(j)
                        )
                ) ||
                !!parseInt(isReceive ? phone_deleted : receiver_phone_deleted) ||
                isUnknow;

            const findMessage = finalListSms.some(
                (item) => (item.temp_id || item.id || '').toString() === messageId?.toString()
            );

            return isNewMessage || (filterUserSelected && filterPhoneActive && !findMessage);
        };

        const _addMessage = (dataMsg) => {
            if (!_checkShowMsg(dataMsg)) {
                return false;
            }

            dispatchAction((prevState) => {
                const oldDataSms = { ...prevState.sms };
                const newAttachments = { ...(oldDataSms.attachments || {}) };

                !dataMsg?.isNewMessage &&
                    dataMsg?.attachments?.forEach((element) => {
                        if (isFileImage(element.type) && Array.isArray(newAttachments?.['images']))
                            newAttachments['images'].unshift(element);
                        if (isFileVideo(element.type) && Array.isArray(newAttachments?.['videos']))
                            newAttachments['videos'].unshift(element);
                        if (element.type === PDF_TYPE && Array.isArray(newAttachments?.['pdf']))
                            newAttachments['pdf'].unshift(element);
                    });

                return {
                    ...prevState,
                    sms: {
                        ...oldDataSms,
                        list: [...(oldDataSms.list || []), dataMsg],
                        attachments: newAttachments
                    }
                };
            });
        };

        const _updateMessage = (id, dataUpdate = {}, isAddImg = false, attachments = []) => {
            dispatchAction((prevState) => {
                const newState = { ...prevState.sms };
                let newStateSms = [...(newState.list || [])];

                newStateSms = newStateSms.map((item) => {
                    if ((item?.temp_id || item?.id || '').toString() === id?.toString()) {
                        return {
                            ...item,
                            ...dataUpdate,
                            isNewMessage: false,
                            temp_id: ''
                        };
                    }
                    return item;
                });

                newState['list'] = newStateSms;

                if (isAddImg && Array.isArray(attachments)) {
                    const newAttachments = { ...newState.attachments };

                    attachments.forEach((item) => {
                        if (isFileImage(item.type) && Array.isArray(newAttachments?.['images']))
                            newAttachments['images'].unshift(item);
                        if (item.type === PDF_TYPE && Array.isArray(newAttachments?.['pdf']))
                            newAttachments['pdf'].unshift(item);
                    });

                    newState['attachments'] = newAttachments;
                }

                return {
                    ...prevState,
                    sms: newState
                };
            });
        };

        const _resendMessage = (id) => {
            const dataQuery = {
                data: { customer_id: finalCustomerId, sms_id: [id] },
                toFormData: false,
                method: 'POST'
            };

            _updateMessage(id, { status: SMS_SENDING, sending: true });

            const _resendFinish = ({ success, message }) => {
                _updateMessage(id, {
                    status: !!success && !message.length ? SMS_SUCCEEDED : SMS_FAILED,
                    sending: false
                });
            };

            const _resendFailed = (response) => {
                _updateMessage(id, { status: SMS_FAILED, sending: false });
                _handleShowWarning({ description: response?.message?.toString() || 'Please try again' });
            };

            clientQuery(RE_SEND_SMS_BY_CUSTOMER, dataQuery, _resendFinish, _resendFailed);
        };

        const _submitSendMessage = (data) => {
            const temp_id = moment().format('x');

            const group = {
                value: `${STRING_TODAY}${moment().format('hh:mmA')}`,
                key: moment().format('MM/DD/YYYY')
            };

            const sender = {
                avatar: profile.avatar || profile.avatar_char || '',
                full_name: profile.name || '',
                id: profile.id
            };

            const filter = {
                receiver_phone_deleted: NOT_RECEIVER_PHONE_DELETED,
                sender_deleted: NOT_RECEIVER_PHONE_DELETED,
                sender_id: profile.id?.toString(),
                receiver_phones: data?.phone || []
            };

            _addMessage({
                ...data,
                group,
                sender,
                filter,
                status: SMS_SENDING,
                type: SMS_TYPE_USERS_SENDER,
                temp_id,
                isNewMessage: true
            });

            setTimeout(() => {
                refWrapper.current.scrollTop = refContainer.current?.offsetHeight;
            }, 0);

            const _addMessageSuccess = ({ data }) => {
                const messageAddSuccess = data?.[0] || {};

                if (_checkShowMsg(messageAddSuccess)) {
                    _updateMessage(temp_id, messageAddSuccess, true, messageAddSuccess.attachments);
                } else {
                    //Remove temp message after check is show
                    dispatchAction((prevState) => {
                        const newState = { ...prevState.sms };
                        let newStateSms = [...(newState.list || [])];

                        newStateSms = newStateSms.filter((item) => {
                            if ((item?.temp_id || item?.id || '').toString() !== temp_id.toString()) {
                                return item;
                            }
                        });

                        newState['list'] = newStateSms;

                        return {
                            ...prevState,
                            sms: newState
                        };
                    });
                }
            };

            const _addMessageFailure = ({ data, message }) => {
                let dataUpdate = { status: SMS_FAILED };

                const shouldUpdateWithResponse = typeof data === 'object' && !data && Object.keys(data).length;

                if (shouldUpdateWithResponse) dataUpdate = data;

                _updateMessage(temp_id, dataUpdate);
                _handleFetchFail({ message, isResetState: false });
            };

            clientQuery(
                SEND_SMS_BY_CUSTOMER,
                {
                    data: {
                        ...data,
                        socket_id: finalSocketId
                    },
                    toFormData: false,
                    method: 'POST'
                },
                _addMessageSuccess,
                _addMessageFailure
            );
        };

        const _markAsReadSms = (sms_id, status) => {
            const newStatus = status === READ_STATUS ? UNREAD_STATUS : READ_STATUS;
            const statusTotal = status === READ_STATUS ? SMS_INCREMENT_TOTAL : SMS_DECREMENT_TOTAL;

            _updateMessage(sms_id, { is_read: newStatus });

            onUpdateTotal(statusTotal);

            dispatch(
                smsUpdateStatus({
                    sms_id: [sms_id],
                    status: newStatus,
                    customer_id: [finalCustomerId],
                    socket_id: finalSocketId
                })
            );
        };

        const _handleBookmark = (isClearDraft = false) => {
            dispatch(
                addNewBookMarkAction({
                    type: 'sms',
                    id: moment().format('x'),
                    data: {
                        customer: { ...customer, conversationId },
                        id: finalCustomerId,
                        draft: {
                            text: isClearDraft ? '' : refMessageBox.current?.getTextareaValue() || '',
                            attachments: isClearDraft ? [] : refMessageBox.current?.getAttachments() || []
                        }
                    }
                })
            );
        };

        function _handleCloseDetail() {
            onClose();
            _handleUpdateExistBookMark();
        }

        function _handleGoback(isBackButton = false) {
            setStep(1);
            isJobPreview && isBackButton && document.getElementById('button_show_number_sms')?.click();
            _handleUpdateExistBookMark();
        }

        function _handleActiveWrapConversation(e, active = false) {
            e && e.stopPropagation();
            if (active) {
                refWrapper.current.classList = 'wrap-conversation-contents scrolls overlay';
                // !isSmsInbox &&
                //     refScrollBottom.current &&
                //     refScrollBottom.current.scrollIntoView({ behavior: 'smooth' });
            } else {
                refWrapper.current.classList = 'wrap-conversation-contents scrolls';
                refMessageBox.current.setDraftSMSValue();
                refMessageBox.current.closeFocus();
            }
        }

        const _handleAssignLead = () => {
            onAssignLead(customerDefaultPhone);
        };

        const _handleCreateLead = () => {
            onCreateLead(customerDefaultPhone);
        };

        function _renderHeader() {
            return (
                <SmsDetailHeader
                    onGoBack={_handleGoback}
                    customer={{
                        ...customerInfo,
                        default_phone: customer.default_phone
                    }}
                    onClose={_handleCloseDetail}
                    phones={finalListPhones}
                    users={users}
                    handleSelectPhone={_handleSelectPhone}
                    handleSelectedUsers={_handleSelectedUsers}
                    onBookmark={_handleBookmark}
                    phoneLoading={phones.loading}
                    isUnknow={isUnknow}
                    onCreateLead={_handleCreateLead}
                    onAssignLead={_handleAssignLead}
                    isShowHeader={isShowHeader}
                />
            );
        }

        function _renderListSms() {
            if (finalSmsIsLoading) {
                return <LoadingSmsList />;
            }

            return (
                <div ref={refContainer} className="message-contents">
                    <div ref={refContentSMs} className="box-conversation">
                        <div ref={refListSMS} className="conts-conversation">
                            <LoadMore ref={refLoadMore} />
                            <ConversationContent
                                showModal={showModal}
                                listSms={finalListSms}
                                isShowButtonDelete={isSupperAdmin && type === TYPE_SHOW_SMS.JOB && !jobId}
                                onMarkSms={_markAsReadSms}
                                onResend={_resendMessage}
                                handleOnClick={_handleOpenConversationDetail}
                                onDelete={_handleShowWarning}
                            />
                        </div>
                        <div onClick={(e) => _handleActiveWrapConversation(e, false)} className="bg-fixed" />
                    </div>
                </div>
            );
        }

        const _handleDropFiles = (files) => {
            let canUpload = true;
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                if (!ACCEPTED_ATTACHMENTS.includes(file?.type)) {
                    canUpload = false;
                }
            }

            if (canUpload) {
                filesEventToData(files, (attach) => {
                    refMessageBox.current.addAttachments(attach);
                });
            } else {
                _handleShowWarning({ description: t('common:message_file_type') });
            }
        };

        const _handleUpdateExistBookMark = () => {
            const isExistBookMark = bookMarkList.some((item) => item.data?.id === finalCustomerId);
            if (!isExistBookMark) return;
            _handleBookmark();
        };

        const _handleOnConfirm = (_, idDelete) => {
            if (!idDelete) {
                refConfirm.current?._close();
                return;
            }

            refConfirm.current?._clearStatusAlert();

            const _handleSuccess = () => {
                dispatchAction((prev) => ({
                    ...prev,
                    sms: { ...prev.sms, list: prev.sms.list.filter((item) => item.id !== idDelete) }
                }));
                refConfirm.current?._close();
            };

            const _handleFail = ({ message }) => {
                refConfirm.current?._setStatusAlert(message);
            };

            const _finally = () => {
                refConfirm.current?._setLoadingBtnSave(false);
            };

            clientQuery(
                actionDeleteSms(idDelete),
                { method: 'DELETE', data: {} },
                _handleSuccess,
                _handleFail,
                _finally
            );
        };

        const _handleShowWarning = (data) => {
            const content =
                typeof data === 'string'
                    ? { title: t('delete_sms'), description: t('delete_sms_confirm'), dataConfirm: data }
                    : data;

            refConfirm.current._open({ title: t('addons:warning'), dataConfirm: null, ...content });
        };

        return (
            <div
                id={'modal_sms_job_customer'}
                className={classNames('notification-dropdown notification-sms conversation-sms step-2', {
                    active: isActive,
                    '--unknown': isUnknow
                })}
            >
                <div className="conversation-details">
                    {_renderHeader()}
                    <div
                        ref={refWrapper}
                        onScroll={(e) => _handleOnScroll(e)}
                        className={'wrap-conversation-contents scrolls'}
                    >
                        {_renderListSms()}
                        <MessageBox
                            ref={refMessageBox}
                            customerName={customer?.full_name || ''}
                            customerId={finalCustomerId}
                            receivers={finalReciver()}
                            toggleActive={_handleActiveWrapConversation}
                            onSubmit={_submitSendMessage}
                            autoOpenBoxMessage={autoOpenBoxMessage || isPhoneAction}
                            isUnknow={isUnknow}
                            defaultSender={defaultSender}
                        />
                    </div>
                    <RichTextDropzone
                        ref={refDropWarning}
                        showIcon
                        pointerEventNone={false}
                        description={t('common:description_upload_file')}
                        onDrop={_handleDropFiles}
                    />
                    <GDPreviewAttach ref={refPreviewAttach} preventSetSource />
                </div>
                <GDModalWarning
                    ref={refConfirm}
                    id="modal_delete_sms"
                    isLargeContent={false}
                    buttonSave
                    titleButtonConfirm={t('confirm')}
                    onConfirm={_handleOnConfirm}
                />
                <RealTimeConverDetail
                    finalSocketId={finalSocketId}
                    onAddMessage={_addMessage}
                    customerId={finalCustomerId}
                    customer={customer}
                />
            </div>
        );
    }
);

export default ConversationDetail;
